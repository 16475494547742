import React from 'react';
import { Button, Group } from '@screentone/core';
import type { EditImageMeta } from '../../types';

type EditImageMetaFooterProps = {
  /** a flag that indicates whether or not images are selected */
  disableApplyButton: boolean;
  /** set metadata to images */
  onApplyMetadata(payload: Partial<EditImageMeta>): void;
  /** remove metadata from the bulk edit field */
  onCancel(): void;
};

const EditImageMetaFormFooter = ({ disableApplyButton, onApplyMetadata, onCancel }: EditImageMetaFooterProps) => {
  return (
    <Group.Item direction="column" gap="sm" margin={{ all: 'md' }}>
      <Button
        data-testid="edit-metadata-apply"
        disabled={disableApplyButton}
        fullWidth
        onClick={onApplyMetadata}
        primary
      >
        Save
      </Button>
      <Button
        data-testid="edit-metadata-cancel"
        disabled={disableApplyButton}
        secondary
        onClick={onCancel}
        fullWidth
        margin={{ top: 'sm' }}
      >
        Reset
      </Button>
    </Group.Item>
  );
};

export default EditImageMetaFormFooter;
