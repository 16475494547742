import React, { useState } from 'react';
import { Button, IconAI, Token, Typography } from '@screentone/core';

import userImageDetail from '../../../hooks/useImageDetail';
import useConfig from '../../../hooks/useConfig';
import useAlert from '../../../hooks/useAppAlerts';

function AiCaptioning({ setImage }: { setImage?: any }) {
  const { image } = userImageDetail();
  const { setAlert } = useAlert();
  const { authFetch } = useConfig();
  const [generating, setGenerating] = useState(false);

  const generateAiCaptioning = () => {
    setGenerating(true);
    const encodedPublicId = encodeURIComponent(image.public_id);
    authFetch(`/api/:property/${encodedPublicId}`, {
      method: 'POST',
      body: JSON.stringify({
        aiCaptioning: true,
      }),
    })
      .then((data: any) => {
        console.log('data: ', data);
        setImage(data);
      })
      .catch((err: Error) => {
        console.error('handleApplyTags Error: ', err);
        setAlert('Error generating AI captioning', { type: 'error' });
      })
      .finally(() => setGenerating(false));
  };
  return (
    <>
      {image.info?.detection?.captioning?.data?.caption ? (
        <Typography color="asphalt" size="sm">
          <Token icon={IconAI} color="ice">
            Cloudinary's AI Image Captioning
          </Token>{' '}
          {image.info.detection.captioning.data.caption}
        </Typography>
      ) : (
        <Button tertiary size="sm" icon={IconAI} onClick={() => generateAiCaptioning()} disabled={generating}>
          Generate AI Captioning
        </Button>
      )}
    </>
  );
}

export default AiCaptioning;
