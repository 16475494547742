{
  "KEY": "marketwatch",
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": false
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://images-marketwatch.dev.djtools.dowjones.io/"
    },
    "dev": {
      "default": "https://images-marketwatch.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": "https://images.stg.mktw.net/"
    },
    "prd": {
      "default": "https://images.mktw.net/"
    }
  },
  "PREVIEW_SIZES": {
    "1:1:thumb": {
      "isThumbnail": true,
      "ar": 1,
      "label": "Thumb 1:1"
    },
    "16:9:thumb": {
      "isThumbnail": true,
      "ar": 1.78,
      "label": "Thumb 16:9"
    },
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    }
  },
  "EAGER": [
    { "width": 940, "height": 636, "pixel_ratio": 2, "format": "avif" },
    { "width": 760, "height": 506, "pixel_ratio": 2, "format": "avif" },
    { "width": 620, "height": 349, "pixel_ratio": 2, "format": "avif" },
    { "width": 300, "height": 169, "pixel_ratio": 2, "format": "avif" },
    { "width": 278, "height": 157, "pixel_ratio": 2, "format": "avif" }
  ],
  "SLACK": {
    "REMOVED_IMAGES": {
      "local": "C02PRBFBBH7",
      "dev": "C02PRBFBBH7",
      "int": "C02PRBFBBH7",
      "prd": "C02TQARGB47"
    }
  },
  "ALT_SIZES": [
    {
      "key": "1:1",
      "label": "1:1",
      "width": 620,
      "height": 620
    },
    {
      "key": "3:2",
      "label": "3:2",
      "width": 620,
      "height": 413
    },
    {
      "key": "16:9",
      "label": "16:9",
      "width": 460,
      "height": 258
    }
  ],
  "DEFAULTS": {},
  "COLORS": [],
  "MD5_4_DOMAIN": {
    "local": "d32d",
    "dev": "d32d",
    "int": "96ef",
    "prd": "61bd"
  },
  "FORM_VALIDATION": ["headline"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "getty": {
      "label": "Getty",
      "folder": "wires/Getty",
      "subSources": {
        "afp": {
          "label": "AFP",
          "folder": "wires/AFP"
        }
      }
    },
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "legacy": {
          "label": "Legacy",
          "folder": ":property/smartcrop"
        },
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "TAGS": {
    "TOPWIRES": {
      "key": "TopWires",
      "label": "Top Wires",
      "color": "ice",
      "icon": "award",
      "title": "Image Selected as Top Wires"
    },
    "SELECTS": {
      "key": "MARKETWATCHSelects",
      "label": "MW Selects",
      "color": "ice",
      "icon": "star",
      "title": "Image Editorially Selected",
      "editable": true
    }
  },
  "ADVANCED_SEARCH": {
    "show_advanced": true,
    "keywords": [
      { "label": "Top Wires", "name": "TopWires" },
      { "label": "MW Selects", "name": "MARKETWATCHSelects" }
    ],
    "region": [
      { "label": "Tri-State Area", "name": "GNY" },
      { "label": "Domestic (US)", "name": "Domestic" },
      { "label": "International", "name": "International" }
    ],
    "categories": [
      { "label": "Sport", "name": "Sport" },
      { "label": "Entertainment", "name": "Entertainment" }
    ]
  },
  "SEARCH_FIELDS": [
    "metadata.headline",
    "metadata.caption",
    "metadata.credit",
    "metadata.contact",
    "context.\"tags_$PROPERTY$\"",
    "context.\"published_labels_$PROPERTY$\""
  ]
}
