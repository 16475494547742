import React from 'react';
import { Button, Dialog, Group, useModalPortal, useModalState } from '@screentone/core';

import CompareImageContent from './CompareImageContent.component';

import type { ImageType } from '../../types';

type CompareImageQualityTypes = {
  /** object representing image's data */
  image: ImageType;
  publishedId?: string;
};

function CompareImageQuality({ image, publishedId }: CompareImageQualityTypes) {
  const { renderNode } = useModalPortal();
  const { open, openModal, closeModal } = useModalState();

  return (
    <>
      <Group margin={{ all: 'sm' }}>
        <Button
          data-testid="compare-image-btn"
          tertiary
          fullWidth
          onClick={() => {
            openModal();
          }}
        >
          Compare Image Quality
        </Button>
      </Group>
      <Dialog
        style={{ width: '95vw' }}
        aria-labelledby="dialogTitle"
        aria-describedby="dialogDesc"
        onDismiss={(e: Event) => {
          closeModal();
        }}
        status={open ? 'open' : 'closed'}
        renderNode={renderNode}
        size="xl"
      >
        <Dialog.Title id="dialogTitle">Compare Image Quality</Dialog.Title>
        <Dialog.Content id="dialogDesc">
          {open && <CompareImageContent image={image} publishedId={publishedId} />}
        </Dialog.Content>
      </Dialog>
    </>
  );
}

export default CompareImageQuality;
