import { IconError, Alert } from '@screentone/core';
import startCase from 'lodash/startCase';
import React from 'react';

import UseUploadType from '../../hooks/useUpload/types';

/**
 * A utility function for checking the validation object for errors
 */
export const getErrors = (validation: UseUploadType.Validation.Validation, requiredFields: string[]) =>
  Object.keys(validation).reduce((total: string[], current: string) => {
    if (
      (validation[current as keyof UseUploadType.Validation.Validation] ||
        validation[current as keyof UseUploadType.Validation.Validation] === null) &&
      requiredFields.includes(startCase(current))
    )
      return total.concat(startCase(current));
    return total;
  }, []);

/**
 * Text that marks the EditMetadataCard as containing errors
 */
const ErrorText = ({ errors }: { errors: string[] }) => (
  <Alert type="error" icon={IconError} inline>
    Invalid{' '}
    {errors.map((err, i) => (
      <React.Fragment key={err}>
        <strong key={err}>{i < errors.length - 2 ? `${err},` : err}</strong>
        {i === errors.length - 2 && ' and '}
      </React.Fragment>
    ))}
  </Alert>
);

export default ErrorText;
