{
  "KEY": "pen",
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": false
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://dj-development-res.cloudinary.com/"
    },
    "dev": {
      "default": "https://images-pen.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": "https://images.stg.penews.com/"
    },
    "prd": {
      "default": "https://images.penews.com/"
    }
  },
  "PREVIEW_SIZES": {
    "1:1:thumb": {
      "isThumbnail": true,
      "ar": 1,
      "label": "Thumb 1:1"
    },
    "3:2:thumb": {
      "isThumbnail": true,
      "ar": 1.5,
      "label": "Thumb 3:2"
    },
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "4:3": {
      "ar": 1.33,
      "label": "4:3"
    },
    "2:1": {
      "ar": 2,
      "label": "2:1"
    },
    "2:3": {
      "ar": 0.66,
      "label": "2:3"
    },

    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    }
  },
  "EAGER": [
    { "width": 1280, "height": 853, "pixel_ratio": 2, "format": "avif" },
    { "width": 1280, "height": 720, "pixel_ratio": 2, "format": "avif" },
    { "width": 604, "height": 403, "pixel_ratio": 2, "format": "avif" },
    { "width": 300, "height": 200, "pixel_ratio": 2, "format": "avif" },
    { "width": 150, "height": 100, "pixel_ratio": 2, "format": "avif" }
  ],
  "SLACK": {
    "REMOVED_IMAGES": {
      "local": "C02PRBFBBH7",
      "dev": "C02PRBFBBH7",
      "int": "C02PRBFBBH7",
      "prd": "C02TQAQ5HEK"
    }
  },
  "ALT_SIZES": [
    {
      "key": "1:1",
      "label": "1:1",
      "width": 1280,
      "height": 1280
    },
    {
      "key": "2:1",
      "label": "2:1",
      "width": 1280,
      "height": 640
    },
    {
      "key": "3:2",
      "label": "3:2",
      "width": 1280,
      "height": 853
    },
    {
      "key": "16:9",
      "label": "16:9",
      "width": 1280,
      "height": 720
    },
    {
      "key": "4:5",
      "label": "4:5",
      "width": 1280,
      "height": 1600
    }
  ],
  "DEFAULTS": {},
  "COLORS": [],
  "MD5_4_DOMAIN": {
    "local": "cd7b",
    "dev": "cd7b",
    "int": "b0dc",
    "prd": "cf36"
  },
  "FORM_VALIDATION": ["headline"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "legacy": {
          "label": "Legacy",
          "folder": ":property/smartcrop"
        },
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "SEARCH_FIELDS": [
    "metadata.headline",
    "metadata.caption",
    "metadata.credit",
    "metadata.contact",
    "context.\"tags_$PROPERTY$\"",
    "context.\"published_labels_$PROPERTY$\""
  ]
}
