import startCase from 'lodash/startCase';
import { parse } from '@screentone/addon-calendar';
import UseUploadType from './types';
import { constants } from '../../utils';
import type { PropertyType } from '../../types';
import { requiredFieldsForPublisher } from '../../utils/helpers';

// the default maximum string length for a cloudinary metadata field
export const CLOUDINARY_MAXIMUM_STRING_LENGTH = 1024;

/**
 * The validation object can contain three different types:
 *   - string: some kind of error
 *   - false: a valid field
 *   - null: an empty field
 */

function getValidationRequired(property: PropertyType) {
  const requiredFields = requiredFieldsForPublisher(property as PropertyType);
  const currentList = {
    caption: false,
    credit: false,
    contact: false,
    datePhotographed: false,
    headline: false,
    slug: false,
    altText: false,
  };
  return { ...currentList, ...requiredFields };
}

export type ValidateType = {
  caption: typeof caption;
  contact: typeof contact;
  credit: typeof credit;
  datePhotographed: typeof datePhotographed;
  headline: typeof headline;
  slug: typeof slug;
};

/**
 * Function to validate caption for image metadata
 */
const caption = (state: Partial<UseUploadType.Metadata.Metadata>): string | false | null => {
  if (state.caption) {
    // errors
    if (state.caption.length > CLOUDINARY_MAXIMUM_STRING_LENGTH) {
      const excessLength = state.caption.length - CLOUDINARY_MAXIMUM_STRING_LENGTH;
      return `Caption exceeds maximum length (${CLOUDINARY_MAXIMUM_STRING_LENGTH}) by ${excessLength} characters.`;
      
    }

    // valid
    return false;
  }

  // empty
  return null;
  // return false;
};

/**
 * Function to validate contact for image metadata
 */
const contact = (state: Partial<UseUploadType.Metadata.Metadata>): string | false | null => {
  if (state.contact) {
    // errors
    if (state.contact.length > CLOUDINARY_MAXIMUM_STRING_LENGTH) {
      return `Contact exceeds maximum length (${CLOUDINARY_MAXIMUM_STRING_LENGTH}).`;
    }

    // valid
    return false;
  }

  // empty
  return null;
  // return false;
};

/**
 * Function to validate credit for image metadata
 */
const credit = (state: Partial<UseUploadType.Metadata.Metadata>): string | false | null => {
  if (state.credit) {
    // errors
    if (state.credit.length > CLOUDINARY_MAXIMUM_STRING_LENGTH) {
      return `Credit exceeds maximum length (${CLOUDINARY_MAXIMUM_STRING_LENGTH}).`;
    }

    // valid
    return false;
  }

  // uncredited
  // if (state.uncredited) return false;

  // empty
  return null;
  // return false;
};

/**
 * Function to validate datePhotographed for image metadata
 *
 * **NOTE:** datePhotographed's validation is handled manually
 */
const datePhotographed = (state: Partial<UseUploadType.Metadata.Metadata>): string | false | null => {
  // state.datePhotographed may contain error text - check for that error
  const testDate = state.datePhotographed?.length
    ? parse(state.datePhotographed, constants.DATE_FORMATS.CLOUDINARY, new Date())
    : new Date();

  // error - error has been passed instead of date
  if (Number.isNaN(testDate.getTime())) {
    return state.datePhotographed as string;
  }

  // valid
  if (state.datePhotographed) return false;

  // empty
  return null;
  // return false;
};

/**
 * Function to validate headline for image metadata
 */
const headline = (state: Partial<UseUploadType.Metadata.Metadata>): string | false | null => {
  if (state.headline) {
    // errors
    if (state.headline.length > CLOUDINARY_MAXIMUM_STRING_LENGTH) {
      return `Headline exceeds maximum length (${CLOUDINARY_MAXIMUM_STRING_LENGTH}).`;
    }

    // valid
    return false;
  }

  // empty
  return null;
};

/**
 * Function to validate slug for image metadata
 */
const slug = (state: Partial<UseUploadType.Metadata.Metadata>): string | false | null => {
  if (state.slug) {
    // errors
    if (state.slug.length > constants.MAX_SLUG_LENGTH) {
      return `Slug exceeds maximum length (${state.slug.length}/${constants.MAX_SLUG_LENGTH}).`;
    }
    
    // valid
    return false;
  }

  // empty
  return null;
};
/**
 * Given the state of a metadata object, validate the entire object
 */
const all = (state: Partial<UseUploadType.Metadata.Metadata>): UseUploadType.Validation.Validation => ({
  caption: caption(state),
  contact: contact(state),
  credit: credit(state),
  datePhotographed: datePhotographed(state),
  headline: headline(state),
  slug: slug(state),
});

const totalRequiredFields = (property: PropertyType) => {
  const validationRequired = getValidationRequired(property as PropertyType);

  return Object.keys(validationRequired).reduce((total: string[], current: string) => {
    if (validationRequired[current as keyof UseUploadType.Validation.Validation])
      return total.concat(startCase(current));
    return total;
  }, []);
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  all,
  caption,
  contact,
  credit,
  datePhotographed,
  headline,
  slug,
  totalRequiredFields,
};
