import React from 'react';

import { Typography } from '@screentone/core';
import MarkdownBlock from '../../../components/MarkdownBlock';

function DeveloperDocs() {
  return (
    <>
      <Typography variant="header1">Image URL Structure</Typography>
      <MarkdownBlock
        md={`
This document outlines all the image domains used by Image Manager

> Note: Jan 2023. We are moving away from the **djnews.tools** domain on dev in favor of
> **dev.djtools.dowjones.io** moving forward. The domain for mansion global has already 
> been set up under **djnews.tools** but should also move to **dev.djtools.dowjones.io**


## Public Images

Public images are images that have been published, uploaded images are automatically published. 
Image Manager ID's start with \`im-\`. Each property has its own public domain per environment.

The public develop domain are not based the properties primary domain.

### Develop*

| Property             | URL                                                            |
| :------------------- | :------------------------------------------------------------- |
| Barron's		         | https://images-barrons.dev.djtools.dowjones.io/{image-id}      |
| FN London		         | https://images-fnlondon.dev.djtools.dowjones.io/{image-id}     |
| Mansion Global       | https://images-mansionglobal.dev.djnews.tools/{image-id}       |
| _Mansion Global new Domain_   | https://images-mansionglobal.dev.dowjones.io/{image-id}        |
| Mansion Global CN ** | http://images-huanyuju.dev.djtools.dowjones.io/{image-id}      |
| MarketWatch		       | https://images-marketwatch.dev.djtools.dowjones.io/{image-id}  |
| PEN		       		     | https://images-pen.dev.djtools.dowjones.io/{image-id}          |
| WSJ		       		     | https://images-wsj.dev.djtools.dowjones.io/{image-id}          |
| WSJ Opinion		       | https://images-wsjopinion.dev.djtools.dowjones.io/{image-id}   |
| WSJ BuySide		       | https://images-buyside.dev.djtools.dowjones.io/{image-id}      |


### INT (QA/Stage)

| Property             | URL                                                            |
| :------------------- | :------------------------------------------------------------- |
| Barron's	           | https://images.stg.barrons.com/{image-id}                      |
| FN London		         | https://images.stg.fnlondon.com/{image-id}                     |
| Mansion Global	     | https://images.stg.mansionglobal.com/{image-id}                |
| Mansion Global CN ** | http://images.stg.huanyuju.com/{image-id}                      |
| MarketWatch		       | https://images.stg.mktw.net/{image-id}                         |
| PEN			             | https://images.stg.penews.com/{image-id}                       |
| WSJ			             | https://images.stg.wsj.net/{image-id}                          |
| WSJ Opinion		       | https://opinion-images.stg.wsj.net/{image-id}                  |
| WSJ BuySide	 	       | https://images.stg.wsjcommerce.net/{image-id}                  |



### Production

| Property             | URL                                                            |
| :------------------- | :------------------------------------------------------------- |
| Barron's	           | https://images.barrons.com/{image-id}                          |
| FN London		         | https://images.fnlondon.com/{image-id}                         |
| Mansion Global	     | https://images.mansionglobal.com/{image-id}                    |
| Mansion Global CN ** | http://images.huanyuju.com/{image-id}                          |
| MarketWatch		       | https://images.mktw.net/{image-id}                             |
| PEN			             | https://images.penews.com/{image-id}                           |
| WSJ			             | https://images.wsj.net/{image-id}                              |
| WSJ Opinion		       | https://opinion-images.wsj.net/{image-id}                      |
| WSJ BuySide	 	       | https://images.wsjcommerce.net/{image-id}                      |



_Notes:_
 1. _\*   The public development domain is not based on the property's primary domain._
 2. \**  _Mansion Global CN needs to serve HTTP and HTTPS traffic._
      
`}
      />
    </>
  );
}

export default DeveloperDocs;
