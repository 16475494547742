import wsj from './json/wsj.json';
import wsjopinion from './json/wsjopinion.json';
import wsjcommerce from './json/wsjcommerce.json';

import barrons from './json/barrons.json';
import mansionglobal from './json/mansionglobal.json';
import marketwatch from './json/marketwatch.json';
import fnlondon from './json/fnlondon.json';
import pen from './json/pen.json';
import ydj from './json/ydj.json';
import rn from './json/rn.json';

import { PropertyTypeConfig } from '../../types';

const configs: {
  [key: string]: PropertyTypeConfig;
} = {
  wsj,
  wsjopinion,
  wsjcommerce,
  barrons,
  marketwatch,
  mansionglobal,
  fnlondon,
  pen,
  ydj,
  rn,
};

export const EXISTING_PROPERTIES = {
  wsj: 'The Wall Street Journal',
  barrons: "Barron's",
  marketwatch: 'MarketWatch',
  mansionglobal: 'Mansion Global',
  wsjopinion: 'WSJ Opinion',
  wsjcommerce: 'WSJ BuySide',
  fnlondon: 'Financial News',
  pen: 'Private Equity News',
  ydj: 'YDJ',
  rn: 'Risk News',
};

function getAllProperties(properties: string[]) {
  const returnObject = properties.reduce((acc: { [key: string]: PropertyTypeConfig }, property: string) => {
    if (Object.keys(EXISTING_PROPERTIES).includes(property)) {
      acc[property] = configs[property];
    }
    return acc;
  }, {});
  return returnObject;
}

export default getAllProperties;
