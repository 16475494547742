{
  "KEY": "wsj",
  "RELATED_PROPERTIES": ["wsjopinion"],
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": false
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://images-wsj.dev.djtools.dowjones.io/"
    },
    "dev": {
      "default": "https://images-wsj.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": "https://images.stg.wsj.net/"
    },
    "prd": {
      "default": "https://images.wsj.net/"
    }
  },
  "EAGER": [
    { "width": 640, "height": 426.5, "pixel_ratio": 2, "format": "avif" },
    { "width": 1152.5, "height": 769, "pixel_ratio": 2, "format": "avif" },
    { "width": 88, "size": 1, "pixel_ratio": 3, "format": "avif" },
    { "width": 110, "height": 110, "pixel_ratio": 2, "format": "avif" },
    { "width": 1280, "height": 720, "format": "avif" },
    { "width": 639, "height": 639, "pixel_ratio": 2, "format": "avif" },
    { "width": 640, "height": 426.5, "pixel_ratio": 2, "ext": "jpg" },
    { "width": 1152.5, "height": 769, "pixel_ratio": 2, "ext": "jpg" },
    { "width": 88, "size": 1, "pixel_ratio": 3, "ext": "jpg" },
    { "width": 110, "height": 110, "pixel_ratio": 2, "ext": "jpg" },
    { "width": 1280, "height": 720, "ext": "jpg" },
    { "width": 639, "height": 639, "pixel_ratio": 2, "ext": "jpg" },
    { "width": 640, "height": 426.5, "pixel_ratio": 2, "ext": "jxl" },
    { "width": 1152.5, "height": 769, "pixel_ratio": 2, "ext": "jxl" },
    { "width": 88, "size": 1, "pixel_ratio": 3, "ext": "jxl" },
    { "width": 110, "height": 110, "pixel_ratio": 2, "ext": "jxl" },
    { "width": 1280, "height": 720, "ext": "jxl" },
    { "width": 639, "height": 639, "pixel_ratio": 2, "ext": "jxl" },
    { "width": 225, "size": 1.5, "format": "avif" },
    { "width": 220, "height": 124, "pixel_ratio": 2, "format": "avif" },
    { "width": 287, "height": 191, "format": "avif" },
    { "width": 207, "height": 138, "format": "avif" },
    { "width": 264, "height": 173, "format": "avif" },
    { "width": 105, "height": 59, "format": "avif" },
    { "width": 700, "size": 1.5, "format": "avif" },
    { "width": 287, "height": 191, "pixel_ratio": 2, "format": "avif" },
    { "width": 207, "height": 138, "pixel_ratio": 2, "format": "avif" },
    { "width": 264, "height": 173, "pixel_ratio": 2, "format": "avif" },
    { "width": 105, "height": 59, "pixel_ratio": 2, "format": "avif" },
    { "width": 700, "size": 1.5, "pixel_ratio": 2, "format": "avif" },
    { "width": 1280, "height": 640, "ext": "jpg" }
  ],
  "SLACK": {
    "REMOVED_IMAGES": {
      "local": "C02PRBFBBH7",
      "dev": "C02PRBFBBH7",
      "int": "C02PRBFBBH7",
      "prd": "C02Q4SVG0U8"
    }
  },
  "PREVIEW_SIZES": {
    "1:1:thumb": {
      "isThumbnail": true,
      "ar": 1,
      "label": "Thumb 1:1"
    },
    "3:2:thumb": {
      "isThumbnail": true,
      "ar": 1.5,
      "label": "Thumb 3:2"
    },
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "2:1": {
      "ar": 2,
      "label": "2:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "5:3": {
      "ar": 1.67,
      "label": "5:3"
    },
    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    },
    "4:5": {
      "ar": 0.8,
      "label": "4:5"
    },
    "2:3": {
      "ar": 0.67,
      "label": "2:3"
    },
    "9:16": {
      "ar": 0.56,
      "label": "9:16"
    }
  },
  "ALT_SIZES": [
    {
      "key": "1:1",
      "label": "1:1",
      "width": 1280,
      "height": 1280
    },
    {
      "key": "2:1",
      "label": "2:1",
      "width": 1280,
      "height": 640
    },
    {
      "key": "3:2",
      "label": "3:2",
      "width": 1280,
      "height": 853
    },
    {
      "key": "16:9",
      "label": "16:9",
      "width": 1280,
      "height": 720
    },
    {
      "key": "4:5",
      "label": "4:5",
      "width": 1280,
      "height": 1600
    },
    {
      "key": "5:3",
      "label": "5:3",
      "width": 1200,
      "height": 720
    },
    {
      "key": "2:3",
      "label": "2:3",
      "width": 800,
      "height": 1200
    },
    {
      "key": "9:16",
      "label": "9:16",
      "width": 720,
      "height": 1280
    }
  ],
  "DEFAULTS": {},
  "COLORS": ["#222222"],
  "MD5_4_DOMAIN": {
    "local": "1032",
    "dev": "1032",
    "int": "78a8",
    "prd": "7cbd"
  },
  "FORM_VALIDATION": ["headline", "caption", "credit", "datePhotographed", "contact", "slug"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "getty": {
      "label": "Getty",
      "folder": "wires/Getty",
      "subSources": {
        "afp": {
          "label": "AFP",
          "folder": "wires/AFP"
        }
      }
    },
    "ap": {
      "label": "AP",
      "folder": "wires/AP"
    },
    "epa": {
      "label": "EPA",
      "folder": "wires/EPA"
    },
    "zuma": {
      "label": "Zuma",
      "folder": "wires/Zuma"
    },
    "bloomberg": {
      "label": "Bloomberg",
      "folder": "wires/Bloomberg"
    },
    "reuters": {
      "label": "Reuters",
      "folder": "wires/Reuters"
    },
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "legacy": {
          "label": "Legacy",
          "folder": ":property/smartcrop"
        },
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "TAGS": {
    "TOPWIRES": {
      "key": "TopWires",
      "label": "Top Wires",
      "color": "ice",
      "icon": "award",
      "title": "Image Selected as Top Wires"
    },
    "SELECTS": {
      "key": "WSJSelects",
      "label": "WSJ Selects",
      "color": "ice",
      "icon": "star",
      "title": "Image Editorially Selected",
      "editable": true
    },
    "PRINT": {
      "key": "WSJPrint",
      "label": "Used In Print",
      "color": "gray",
      "icon": "newspaper",
      "title": "Image Used In Print",
      "editable": true
    }
  },
  "ADVANCED_SEARCH": {
    "show_advanced": true,
    "keywords": [
      { "label": "Top Wires", "name": "TopWires" },
      { "label": "WSJ Selects", "name": "WSJSelects" },
      { "label": "Used In Print", "name": "WSJPrint" }
    ],
    "region": [
      { "label": "Tri-State Area", "name": "GNY" },
      { "label": "Domestic (US)", "name": "Domestic" },
      { "label": "International", "name": "International" }
    ],
    "categories": [
      { "label": "Sport", "name": "Sport" },
      { "label": "Entertainment", "name": "Entertainment" }
    ]
  },
  "SEARCH_FIELDS": [
    "metadata.headline",
    "metadata.caption",
    "metadata.credit",
    "metadata.contact",
    "context.\"tags_$PROPERTY$\"",
    "context.\"published_labels_$PROPERTY$\""
  ],
  "DOWNLOADS": [
    {
      "label": "Campaign Monitor Newsletter",
      "sizes": [
        {
          "label": "900px 3:2 JPEG",
          "width": 900,
          "aspect_ratio": 1.5,
          "format": "jpg",
          "name": "newsletters_3_2_#publishedId#"
        },
        {
          "label": "900px Square JPEG",
          "width": 900,
          "aspect_ratio": 1,
          "format": "jpg",
          "name": "newsletters_square_#publishedId#"
        }
      ]
    }
  ]
}
