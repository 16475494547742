import Calendar, { calendarHooks, format } from '@screentone/addon-calendar';
import { Input, FormLabel, Select, Textarea, FormHelperText } from '@screentone/core';
import React, { useState, useEffect } from 'react';
import { AutoComplete } from '@screentone/addon-autocomplete';

import { requiredFieldsForPublisher } from '../../../utils/helpers';
import { constants, helpers } from '../../../utils';
import { DEFAULT_SEARCH_FIELD_VALUES } from '../../../utils/constants';
import { useConfig } from '../../../hooks/useConfig';

import type { DisableEditField, EditImageMeta, GraphicType } from '../../../types';

import styles from './EditWiresMetadataForm.module.css';
import UseUploadType from '../../../hooks/useUpload/types';
import AltTextTooltip from '../../EditMetadataForm/AltTextTooltip.component';

type WiresFormUpdates = {
  headline: string;
  caption: string;
  credit: string;
  datePhotographed: string;
  graphicType: GraphicType;
  specialInstructions: string;
  category: string;
  city: string;
  state: string;
  country: string;
  subCategories: string;
  region: string;
  altText: string;
};

type EditWiresMetaDataFormProps = {
  /** a state setting function that updates the state of the image's metadata */
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  /** set metadata to images */
  disableRequiredFields?: boolean;
  disableEditField?: DisableEditField;
  validation?: Partial<UseUploadType.Validation.ValidFormFieldsType>;
} & Pick<EditImageMeta, 'headline' | 'credit' | 'contact' | 'caption' | 'datePhotographed' | 'graphicType'> &
  WiresFormUpdates;

/**
 * Filters items in the main array by excluding those present in the exclusion array.
 *
 * @param {string[]} mainArray - The array to filter.
 * @param {string[]} exclusionArray - The array containing items to be excluded.
 * @returns {string[]} - The filtered array with items excluded.
 */
const filterItemsByExclusion = (mainArray: string[], exclusionArray: string[]): string[] => {
  // Use Array.filter() to exclude items present in the exclusion array
  return mainArray.filter((item) => !exclusionArray.includes(item));
};

function EditWiresMetaDataForm({
  disableEditField = {},
  caption,
  contact = '',
  credit,
  datePhotographed,
  headline,
  graphicType,
  altText,
  specialInstructions,
  category,
  city,
  state,
  country,
  subCategories,
  region,
  disableRequiredFields = false,
  validation,
  onChange,
}: EditWiresMetaDataFormProps) {
  const renderNode = calendarHooks.usePortal();
  const {
    session: { property },
  } = useConfig();
  const requiredFields = requiredFieldsForPublisher(property, disableRequiredFields);
  const { contact: disableContact = false } = disableEditField;

  const [tagsCategory, setTagsCategory] = useState<string[]>([]);
  const [tagsRegion, setTagsRegion] = useState<string[]>([]);

  useEffect(() => {
    setTagsRegion([region]);
    setTagsCategory([category]);
  }, [category, region]);

  const handleSelect = (selectedTags: string[], id: 'category' | 'region') => {
    // Determine the appropriate state setter based on the identifier
    const setTags = id === 'category' ? setTagsCategory : setTagsRegion;

    // Set the selected tags in the corresponding state
    setTags(selectedTags);

    // Find the newly selected tag (tag not present in the previous state)
    const updatedValue =
      id === 'category'
        ? selectedTags.find((item) => !tagsCategory.includes(item)) || ''
        : selectedTags.find((item) => !tagsRegion.includes(item)) || '';

    // Trigger the onChange callback with the updated value
    onChange({
      target: {
        id,
        value: updatedValue,
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <FormLabel fullWidth label="Headline" required={requiredFields?.headline || false}>
        <Input
          id="headline"
          data-testid="edit-metadata-headline"
          onChange={({ target }: { target: HTMLInputElement }) => {
            onChange({
              target: {
                id: 'headline',
                value: target.value.trimEnd().length === 0 ? target.value.trimEnd() : target.value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          placeholder={requiredFields.headline ? 'Headline Required' : 'Headline'}
          value={headline}
          error={requiredFields.headline ? !headline.length : false}
        />
      </FormLabel>
      <FormLabel fullWidth label="Caption" required={requiredFields?.caption || false}>
        <Textarea
          id="caption"
          data-testid="edit-metadata-caption"
          onChange={({ target }: { target: HTMLInputElement }) => {
            onChange({
              target: {
                id: 'caption',
                value: target.value.trimEnd().length === 0 ? target.value.trimEnd() : target.value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          value={caption}
          placeholder={requiredFields.caption ? 'Caption Required' : 'Caption'}
          error={(requiredFields.caption && !caption.length) || !!validation?.caption}
        />
        {validation?.caption && <FormHelperText error>{validation?.caption}</FormHelperText>}
      </FormLabel>
      <FormLabel fullWidth label="Credit" required={requiredFields?.credit || false}>
        <Input
          data-testid="edit-metadata-credit"
          id="credit"
          onChange={({ target }: { target: HTMLInputElement }) => {
            onChange({
              target: {
                id: 'credit',
                value: target.value.trimEnd().length === 0 ? target.value.trimEnd() : target.value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          placeholder={requiredFields.credit ? 'Credit Required' : 'Credit'}
          value={credit}
          error={requiredFields.credit ? !credit.length : false}
        />
      </FormLabel>

      {!disableContact && (
        <FormLabel fullWidth label="Contact" required={requiredFields?.contact || false}>
          <Input
            id="contact"
            data-testid="edit-metadata-contact"
            onChange={onChange}
            placeholder="Contact"
            value={contact}
            error={requiredFields.contact ? !contact.length : false}
          />
        </FormLabel>
      )}
      <FormLabel
        data-testid="edit-metadata-date"
        fullWidth
        label="Date Photographed"
        required={requiredFields.datePhotographed || false}
      >
        <Calendar
          align="right"
          calendarProps={{ className: styles.calendar }}
          maxDate={new Date()}
          onSelect={(date: Date) => {
            // don't use the hook for managing state - multiple calendars rendered at same time effect same state
            const datePhotographedValue = date ? format(date, constants.DATE_FORMATS.CLOUDINARY) : '';

            onChange({
              target: {
                id: 'datePhotographed',
                value: datePhotographedValue,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          placeholder={`${constants.DATE_FORMATS.UPLOADS.EDIT_METADATA_FORM.toLowerCase()}`}
          renderNode={renderNode}
          selectedDate={helpers.parseDate(datePhotographed)}
          error={requiredFields.datePhotographed ? !datePhotographed.length : false}
        />
      </FormLabel>
      <FormLabel fullWidth label="Graphic Type" required={requiredFields?.graphicType || false}>
        <Select
          id="graphicType"
          data-testid="edit-metadata-graphic"
          name="Type"
          onChange={onChange}
          value={graphicType as GraphicType}
        >
          {constants.GRAPHIC_TYPES.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
      </FormLabel>

      <FormLabel fullWidth label={<AltTextTooltip />} required={requiredFields?.altText || false}>
        <Textarea
          id="altText"
          data-testid="edit-metadata-altText"
          onChange={onChange}
          value={altText}
          placeholder="Alt Text - a description of the image for SEO & accessibility"
          error={requiredFields.altText ? !altText.length : false}
        />
      </FormLabel>

      <FormLabel fullWidth label="Special Instructions" required={requiredFields?.specialInstructions || false}>
        <Textarea
          id="specialInstructions"
          data-testid="edit-metadata-SpecialInstructions"
          onChange={onChange}
          value={specialInstructions}
          placeholder="Special Intructions"
          error={requiredFields.specialInstructions ? !specialInstructions.length : false}
        />
      </FormLabel>
      <div className={styles.autocomplete_container}>
        <FormLabel fullWidth label="Category" required={requiredFields?.category || false} />
        <AutoComplete
          className={styles.autocomplete}
          dropDownClassName={styles.autocomplete_dropdown}
          id="category"
          data-testid="edit-metadata-Category"
          dropDownItems={filterItemsByExclusion(DEFAULT_SEARCH_FIELD_VALUES.category, tagsCategory)}
          onDismiss={(tag: string[]) => {
            setTagsCategory(tag);
            handleSelect(tag, 'category');
            // const inputCategory = document.querySelector('#category .st_autocomplete__input');
            // if (inputCategory && inputCategory instanceof HTMLInputElement) {
            // inputCategory?.focus();
            // }
          }}
          onSelect={(tag: string[]) => handleSelect(tag, 'category')}
          tags={!category.length ? [] : tagsCategory}
          inputProps={{ readOnly: true }}
          type="text"
          error={requiredFields.category ? !category.length : false}
        />
      </div>
      <FormLabel fullWidth label="City" required={requiredFields?.city || false}>
        <Input
          id="city"
          data-testid="edit-metadata-City"
          onChange={onChange}
          placeholder="City"
          value={city}
          error={requiredFields.city ? !city.length : false}
        />
      </FormLabel>
      <FormLabel fullWidth label="State" required={requiredFields?.state || false}>
        <Input
          id="state"
          data-testid="edit-metadata-state"
          onChange={onChange}
          placeholder="State"
          value={state}
          error={requiredFields.state ? !state.length : false}
        />
      </FormLabel>
      <FormLabel fullWidth label="Country" required={requiredFields?.country || false}>
        <Input
          id="country"
          data-testid="edit-metadata-country"
          onChange={onChange}
          placeholder="Country"
          value={country}
          error={requiredFields.country ? !country.length : false}
        />
      </FormLabel>
      <div className={styles.autocomplete_container}>
        <FormLabel fullWidth label="Region" required={requiredFields?.category || false} />
        <AutoComplete
          className={styles.autocomplete}
          dropDownClassName={styles.autocomplete_dropdown}
          id="region"
          data-testid="edit-metadata-region"
          dropDownItems={filterItemsByExclusion(DEFAULT_SEARCH_FIELD_VALUES.region, tagsRegion)}
          onDismiss={(tag: string[]) => {
            setTagsCategory(tag);
            handleSelect(tag, 'region');
          }}
          onSelect={(tag: string[]) => handleSelect(tag, 'region')}
          tags={!region?.length ? [] : tagsRegion}
          inputProps={{ readOnly: true }}
          error={requiredFields.category ? !category.length : false}
        />
      </div>
      <FormLabel fullWidth label="SubCategories" required={requiredFields?.subCategories || false}>
        <Input
          id="subCategories"
          data-testid="edit-metadata-subCategories"
          onChange={onChange}
          placeholder="SubCategories"
          value={subCategories}
          error={requiredFields.subCategories ? !subCategories.length : false}
        />
      </FormLabel>
    </>
  );
}

export default EditWiresMetaDataForm;
