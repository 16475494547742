import React, { RefObject, useEffect, useState } from 'react';
import {
  Button,
  Group,
  IconCaretLeft,
  IconArrowRight,
  Dialog,
  Typography,
  useModalPortal,
  useModalState,
  IconCheckCircle,
  Subnav,
} from '@screentone/core';
import { useNavigate, useParams } from 'react-router-dom';

import ActionButtons from '../../ActionButtons';
import type { ImageType } from '../../../types';
import { getLatestUploadFromLocalStorage, getLastPublished } from '../../../utils/helpers';
import useAssetManager from '../../../hooks/useAssetManager';

import useImageDetail from '../../../hooks/useImageDetail';
import useConfig from '../../../hooks/useConfig';

import styles from './PageNavigation.module.css';
import useAlert from '../../../hooks/useAppAlerts';
import { useAuth } from '@screentone/addon-auth-wrapper';
import useEditImage from '../../../hooks/useEditImage';
import { constants } from '../../../utils';

type NavbarTypes = {
  /** object representing image's data */
  image: ImageType;
  /** boolean wither to show the Add to Source Button */
  showAddToSource?: boolean;
  /** setImage helper */
  setImage: (res: ImageType) => void;
  /** div container ref */
  divContainerRef: RefObject<HTMLDivElement>;
  /** publishedIdsObj */
  publishedIdsObj?: { [key: string]: { id: string; label: string; url: string } };
};

function PageNavigation({ image, showAddToSource, setImage, divContainerRef, publishedIdsObj }: NavbarTypes) {
  const {
    session: { property },
    authFetch,
  } = useConfig();
  const { setAlert } = useAlert();
  const { getLastUploadedImages, getImage, setLatestUploads } = useAssetManager();
  const { isDirty, publishData, resetData, isSaving, setIsSaving, isSavingWait, setIsSavingWait } = useEditImage();
  const { type: pageType } = useParams();
  const isBatchFlow = pageType === 'batch';
  const { setPublishedId } = useImageDetail();
  const [isSavingBatch, setIsSavingBatch] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const lastUploadedImages = getLastUploadedImages();
  const imIdx = lastUploadedImages.findIndex((id) => id === image.asset_id);
  const hasNext = imIdx < lastUploadedImages.length - 1;
  const backToUploadList = localStorage.getItem(`${property}:backToUploadList`) || 'false';

  const onButtonLeft = () => {
    if (isBatchFlow && imIdx > 0) {
      const prevImage = getImage(lastUploadedImages[imIdx - 1]);
      const publishedId = getLastPublished(prevImage, property);
      setImage(prevImage);
      setPublishedId(publishedId as string);
      navigate(`/${property}/${pageType}/${prevImage.asset_id}/edit/${publishedId}`);
    } else {
      if (backToUploadList === 'true' && !image.isDynamic && lastUploadedImages.length > 1) {
        navigate(`/${property}/upload`);
      } else {
        navigate(`/${property}`);
      }
    }
  };

  const onButtonRightNext = () => {
    const nextImage = getImage(lastUploadedImages[imIdx + 1]);
    if (nextImage) {
      const publishedId = getLastPublished(nextImage, property);
      setImage(nextImage);
      setPublishedId(publishedId as string);
      navigate(`/${property}/${pageType}/${nextImage.asset_id}/edit/${publishedId}`);
      closeModal();
    } else {
      const djUserId = (user?.dj_user_id && user?.dj_user_id.toLowerCase()) || '';
      navigate(`/${property}?user=${djUserId}`);
      closeModal();
    }
  };

  useEffect(() => {
    // Dupe code from upload.layout
    console.log('TODO UPDATE: PAGE getLatestUploadFromLocalStorage'); // eslint-disable-line no-console
    getLatestUploadFromLocalStorage(property, async (id) => await authFetch(`/api/:property/${id}`))
      .then((images: ImageType[]) => {
        setLatestUploads(images);
      })
      .catch((err) => {
        console.error('Error in getLatestUploadFromLocalStorage:', err);
      });
  }, [property]);

  const onButtonRightSaving = () => {
    setIsSaving(true);
    setIsSavingBatch(true);
    setIsSavingWait(true);
    publishData()
      .then(() => {
        setAlert('Image updated', { type: 'success', icon: IconCheckCircle });
        if (hasNext) {
          setIsSavingWait(false);
          onButtonRightNext();
        } else {
          setTimeout(() => {
            setIsSavingWait(false);
            onButtonRightNext();
          }, 3000);
        }
      })
      .catch((error) => {
        console.error('Failed to save image: ', error);
        setAlert('Failed to save image', { type: 'error' });
      })
      .finally(() => {
        setIsSaving(false);
        setIsSavingBatch(false);
        closeModal();
      });
  };

  const { renderNode } = useModalPortal();
  const { open, openModal, closeModal } = useModalState();
  const { open: openDirty, openModal: openModalDirty, closeModal: closeModalDirty } = useModalState();
  const [linkTo, setLinkTo] = useState('');
  const [prevImage, setPrevImage] = useState<ImageType>();
  const [localPublishedId, setLocalPublishedId] = useState('');

  const batchButtonDisabled = () => {
    if (isBatchFlow) {
      if (hasNext && (isSaving || isSavingBatch)) {
        return true;
      } else if (!hasNext && (isSavingWait || isSavingBatch)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Subnav className={styles.wrapper} ref={divContainerRef}>
      <Group align="space-between" fullWidth>
        <Group.Item>
          <Button
            data-testid="back-btn"
            icon={IconCaretLeft}
            tertiary
            onClick={() => {
              if (isDirty()) {
                if (isBatchFlow && imIdx > 0) {
                  const prevImage = getImage(lastUploadedImages[imIdx - 1]);
                  const publishedId = getLastPublished(prevImage, property);
                  setPrevImage(prevImage);
                  setLocalPublishedId(publishedId as string);
                  setLinkTo(`/${property}/${pageType}/${prevImage.asset_id}/edit/${publishedId}`);
                } else {
                  if (backToUploadList === 'true' && !image.isDynamic && lastUploadedImages.length > 1) {
                    setLinkTo(`/${property}/upload`);
                  } else {
                    setLinkTo(`/${property}`);
                  }
                }
                openModalDirty();
              } else {
                onButtonLeft();
              }
            }}
            justified="center"
            disabled={isBatchFlow && imIdx === 0}
          >
            Back
          </Button>
        </Group.Item>
        {isBatchFlow && (
          <Button
            data-testid="adjust-images-next-button"
            primary={!hasNext}
            secondary={hasNext}
            icon={IconArrowRight}
            iconPosition="right"
            disabled={batchButtonDisabled()}
            onClick={() => {
              isDirty() ? openModal() : onButtonRightNext();
            }}
          >
            {hasNext ? 'Next' : constants.MY_IMAGES}
          </Button>
        )}

        {!isBatchFlow && (
          <Group align="end" gap="md">
            <Group.Item>
              <ActionButtons
                image={image}
                showAddToSource={showAddToSource}
                setImage={setImage}
                publishedIdsObj={publishedIdsObj}
              />
            </Group.Item>
          </Group>
        )}

        {open && isDirty() && (
          <Dialog renderNode={renderNode} status={open ? 'open' : 'closed'} onDismiss={closeModal} size="fluid">
            <Dialog.Title data-testid="adjust-img-label">Adjust Images</Dialog.Title>
            <Dialog.Content>
              <Typography margin={{ top: 'md' }} data-testid="description">
                Do you want to save pending changes before going to{' '}
                {isBatchFlow && hasNext ? 'the next image' : constants.MY_IMAGES}?
              </Typography>
            </Dialog.Content>
            <Dialog.Actions>
              <Button
                data-testid="continue-without-save"
                secondary
                type="reset"
                onClick={() => onButtonRightNext()}
                disabled={isSaving}
              >
                Continue without saving
              </Button>
              <Button data-testid="save-and-continue" primary onClick={onButtonRightSaving} disabled={isSaving}>
                Save and continue
              </Button>
            </Dialog.Actions>
          </Dialog>
        )}
        {openDirty && (
          <Dialog
            renderNode={renderNode}
            status={openDirty ? 'open' : 'closed'}
            onDismiss={closeModalDirty}
            size="fluid"
          >
            <Dialog.Title>Edit Image</Dialog.Title>
            <Dialog.Content>
              <Typography margin={{ top: 'md' }}>
                Do you want to save pending changes before leaving this page?
              </Typography>
            </Dialog.Content>
            <Dialog.Actions>
              <Button
                secondary
                type="reset"
                disabled={isSaving}
                onClick={() => {
                  if (prevImage) {
                    setImage(prevImage);
                  }
                  if (localPublishedId) {
                    setPublishedId(localPublishedId);
                  }
                  if (linkTo) {
                    navigate(linkTo);
                  }
                  resetData();
                  closeModalDirty();
                }}
              >
                Continue without saving
              </Button>
              <Button
                primary
                disabled={isSaving}
                onClick={() => {
                  setIsSaving(true);
                  setIsSavingBatch(true);
                  publishData()
                    .then(() => {
                      setIsSaving(false);
                      setIsSavingBatch(false);
                      if (prevImage) {
                        setImage(prevImage);
                      }
                      if (localPublishedId) {
                        setPublishedId(localPublishedId);
                      }
                      if (linkTo) {
                        navigate(linkTo);
                      }
                      setAlert('Image updated', { type: 'success', icon: IconCheckCircle });
                      closeModalDirty();
                    })
                    .catch(() => {
                      setIsSaving(false);
                      setIsSavingBatch(false);
                      console.error('Failed to save image');
                      setAlert('Failed to save image', { type: 'error' });
                    });
                }}
              >
                Save and continue
              </Button>
            </Dialog.Actions>
          </Dialog>
        )}
      </Group>
    </Subnav>
  );
}

export default PageNavigation;
