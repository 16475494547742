import React, { useEffect, useState } from 'react';
import {
  Alert,
  IconWarning,
  Button,
  Divider,
  Group,
  IconCircleFilled,
  IconCheckCircle,
  Illustration,
  Typography,
  FormHelperText,
} from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';

import useConfig from '../../../hooks/useConfig';
import useEditImage from '../../../hooks/useEditImage';
import useAlert from '../../../hooks/useAppAlerts';

import Slug from './Slug';
import FocalArea from './FocalArea';
import ThumbnailFocalArea from './ThumbnailFocalArea';
import Crop from './CropImage';
import BackgroundColor from './BackgroundColor';

import { getResizeOnlyFlag } from '../../../utils/helpers';
import useImageDetail from '../../../hooks/useImageDetail';
import type { ImageType } from '../../../types';

type PanelProps = {
  currentPanel: {
    REQUIRED?: any;
    DESCRIPTION?: string;
    LABEL: string;
    KEY: string;
  };
  Component: React.ComponentType<any>;
  disabled?: boolean;
};

const Panel = ({ currentPanel, Component, disabled }: PanelProps) => {
  const { isDirty } = useEditImage();
  const showDot = isDirty(currentPanel.KEY);

  return (
    <>
      <Group direction="column" gap="xs" align="initial" style={{ padding: '0 var(--st-spacer-md)' }}>
        <Typography color="ice" weight="bold" size="sm">
          {currentPanel.LABEL} {showDot && <IconCircleFilled size="sm" color="blurple" title="Unsaved changes" />}
          {currentPanel.REQUIRED && (
            <Typography color="lava" inline>
              {' *'}
            </Typography>
          )}
        </Typography>
        <Component disabled={disabled !== undefined ? disabled : false} panelKey={currentPanel.KEY} />
        {currentPanel.DESCRIPTION && (
          <FormHelperText>
            {currentPanel.DESCRIPTION.startsWith('Optional') ? (
              <>
                <em>Optional</em>
                {currentPanel.DESCRIPTION.replace('Optional', '')}
              </>
            ) : (
              currentPanel.DESCRIPTION
            )}
          </FormHelperText>
        )}
      </Group>
      <Divider margin={{ top: 'smd' }} />
    </>
  );
};

const Panels = ({ children }: { children?: React.ReactNode }) => {
  const { setAlert } = useAlert();
  const { user, userAccess } = useAuth();
  const {
    authFetch,
    session: { property },
  } = useConfig();
  const { CONTENT_COMPONENT_CONST, isDirty, publishData, resetData, isSaving, setIsSaving, setIsSavingWait } =
    useEditImage();
  const { image, transformationType, publishedId, setImage } = useImageDetail();
  const [isLegacySmartCrop, setIsLegacySmartCrop] = useState(transformationType === 'djSmartCrop');
  const isSemiTransparent = image.semi_transparent || image?.image_analysis?.transparent || false;
  const resizeOnly = getResizeOnlyFlag(image, property);
  const hasChanges = isDirty();
  const [isUpgrading, setIsUpgrading] = useState(false);

  useEffect(() => {
    setIsLegacySmartCrop(transformationType === 'djSmartCrop');
  }, [transformationType]);

  const handleApplyMetadata = (publicId: string, metadata: object) => {
    return new Promise((resolve, reject) => {
      const encodedPublicId = encodeURIComponent(publicId);

      authFetch(`/api/:property/${encodedPublicId}`, {
        method: 'POST',
        body: JSON.stringify({
          ...metadata,
          publishedId,
        }),
      })
        .then((data: any) => {
          setImage(data);
          setAlert('Metadata has been updated.', { type: 'success' });
          resolve(data);
        })
        .catch((err: Error) => {
          console.error('Applying Metadata Err: ', err);
          setAlert("Error updating image's metadata, try again.", { type: 'error' });
          reject();
        });
    });
  };

  let panels = <></>;

  if (isLegacySmartCrop) {
    panels = (
      <>
        <Alert
          inline
          fullWidth
          type="warning"
          icon={IconWarning}
          margin={{ top: 'none', bottom: 'md', left: 'md', right: 'md' }}
        >
          This image uses our legacy Smart Crop renderer. To make change you need to upgrade to our new renderer.
        </Alert>
        <Group margin={{ horizontal: 'md', bottom: 'md' }} gap="sm">
          <Button
            primary
            fullWidth
            disabled={isUpgrading}
            onClick={() => {
              setIsUpgrading(true);
              handleApplyMetadata(image.public_id, { transformationType: 'cldDefault' })
                .then((image) => {
                  setImage(image as ImageType);
                })
                .finally(() => {
                  setIsUpgrading(false);
                });
            }}
          >
            Upgrade to new renderer
          </Button>
        </Group>
      </>
    );
  } else {
    // Full Edit - Edit slug and focal/crop options
    // Chart Image -  should not get this far but if they do, no edit options and can not edit slug
    // Dynamic Image - no edit options?  not sure about slug? but for first release slug is not editable
    // GIF - Edit slug only - no other edit options
    // Resize Only - Edit slug and background options only
    if (image?.isChart) {
      panels = <></>;
    } else if (image?.isDynamic) {
      panels = (
        <>
          <Panel currentPanel={CONTENT_COMPONENT_CONST.BACKGROUND_COLOR} Component={BackgroundColor} />
        </>
      );
    } else if (image?.format === 'gif') {
      panels = (
        <>
          <Panel currentPanel={CONTENT_COMPONENT_CONST.FOCAL_AREA} Component={FocalArea} />
          {(resizeOnly || isSemiTransparent) && (
            <Panel currentPanel={CONTENT_COMPONENT_CONST.BACKGROUND_COLOR} Component={BackgroundColor} />
          )}
        </>
      );
    } else if (resizeOnly) {
      panels = <Panel currentPanel={CONTENT_COMPONENT_CONST.BACKGROUND_COLOR} Component={BackgroundColor} />;
    } else {
      panels = (
        <>
          <Panel currentPanel={CONTENT_COMPONENT_CONST.FOCAL_AREA} Component={FocalArea} />
          <Panel currentPanel={CONTENT_COMPONENT_CONST.THUMB_FOCAL_AREA} Component={ThumbnailFocalArea} />
          <Panel currentPanel={CONTENT_COMPONENT_CONST.CROP} Component={Crop} />
          {isSemiTransparent && (
            <Panel currentPanel={CONTENT_COMPONENT_CONST.BACKGROUND_COLOR} Component={BackgroundColor} />
          )}
        </>
      );
    }
  }

  // canEdit - user must have edit permission `userAccess([`${property}.edit`, `${property}.edit.focalPoint`]))` or uploadedByCurrentUser
  const canEdit =
    userAccess([`${property}.edit`, `${property}.edit.focalPoint`]) ||
    user?.dj_user_id.toLowerCase() === image?.metadata?.dj_user_id;

  const hideSlug = isLegacySmartCrop || image?.isDynamic;

  return (
    <Group margin={{ top: 'smd' }} fullHeight fullWidth gap="none">
      <Group.Item fullWidth>
        {!hideSlug && <Panel currentPanel={CONTENT_COMPONENT_CONST.SLUG} Component={Slug} disabled={!canEdit} />}
        {canEdit ? (
          panels
        ) : (
          <>
            <Illustration name="no-access" style={{ width: '50%', margin: 'auto' }} />
            <Typography align="center" variant="label3" margin={{ horizontal: 'md' }}>
              You do not have permission to edit this image
            </Typography>
          </>
        )}

        {/* 
      <Panel currentPanel={CONTENT_COMPONENT_CONST.EDIT} Component={Edit} />
      <Panel currentPanel={CONTENT_COMPONENT_CONST.OVERLAY} Component={Overlay} />
      <Panel currentPanel={CONTENT_COMPONENT_CONST.ENHANCE} Component={Enhance} />
      */}

        {children}
      </Group.Item>
      <Group.Item fullWidth>
        {!isLegacySmartCrop && canEdit && (
          <Group margin={{ horizontal: 'md', bottom: 'md' }} gap="sm">
            <Button
              data-testid="save-btn"
              primary
              fullWidth
              disabled={!hasChanges || isSaving}
              onClick={() => {
                setIsSaving(true);
                setIsSavingWait(true);
                publishData()
                  .then(() => {
                    setAlert('Image updated', { type: 'success', icon: IconCheckCircle });
                  })
                  .catch(() => {
                    console.error('Failed to save image');
                    setAlert('Failed to save image', { type: 'error' });
                  })
                  .finally(() => {
                    setIsSaving(false);
                    setTimeout(() => {
                      setIsSavingWait(false);
                    }, 3000);
                  });
              }}
            >
              Save
            </Button>

            <Button
              data-testid="reset-btn"
              secondary
              fullWidth
              disabled={!hasChanges || isSaving}
              onClick={() => {
                resetData();
              }}
            >
              Reset
            </Button>
          </Group>
        )}
      </Group.Item>
    </Group>
  );
};

export default Panels;
