//https://github.com/the-bugging/react-use-downloader/issues/17
import React, { useEffect, useState } from 'react';
import {
  Button,
  IconDownload,
  LoaderBar,
  Dropdown,
  IconThreeDotsVer,
  Group,
  FormLabel,
  Divider,
  Wrapper,
} from '@screentone/core';
import { getImageUrl } from '../../../utils/helpers';

import useDownloader from '../../../../node_module_resolver/react-use-downloader';
import useConfig from '../../../hooks/useConfig';
import { constants, regex } from '../../../utils';
import { ImageType } from '../../../types';
import { useAuth } from '@screentone/addon-auth-wrapper';
import CompareImageQuality from '../../_temp_CompareImageQuality';

type DownloadButtonsProps = {
  image: ImageType;
  publishedId?: string;
  disabled?: boolean;
  className?: string;
};

function DownloadButtons({ disabled, className, image, publishedId: id }: DownloadButtonsProps) {
  try {
    const {
      session: { env, property },
    } = useConfig();
    const { userAccess } = useAuth();

    const [activeDownload, setActiveDownload] = useState<null | string>(null);
    const downloads = constants.PROPERTIES.getDownloads(property);

    // const hasAccess = userAccess(['developer', 'app_admin', 'beta_user'], user);
    const isDeveloper = userAccess('developer');
    const isAdmin = userAccess('app_admin');
    const isBetaUser = userAccess('beta_user');
    const hasAccess = isDeveloper || isAdmin || isBetaUser;

    const publishedId = regex.id.im.test(id || '') ? id : undefined;

    const originalImageUrl = image.secure_url;
    const originalImageFileName = `${image.public_id}.${image.format}`;

    const [isDropDrownOpen, setIsDropDrownOpen] = useState(false);
    const { download: downloadImage, percentage, isInProgress } = useDownloader();
    useEffect(() => {
      if (percentage === 100) {
        setIsDropDrownOpen(!isDropDrownOpen);
        setActiveDownload(null);
      }
    }, [percentage]);

    return (
      <Dropdown
        data-testid="share-dropdown"
        position="right"
        className={className ? className : null}
        padding={{ all: 'none' }}
        trigger={<IconThreeDotsVer />}
        onToggle={() => setIsDropDrownOpen(!isDropDrownOpen)}
        open={isDropDrownOpen}
      >
        <Group gap="sm" margin={{ all: 'sm' }}>
          <Button
            data-testid="download-btn"
            fullWidth
            tertiary
            disabled={isInProgress || disabled}
            icon={IconDownload}
            componentEl="a"
            href={originalImageUrl}
            onClick={(e: Event) => {
              setActiveDownload('original');
              e.preventDefault();
              downloadImage(originalImageUrl, originalImageFileName);
            }}
          >
            Download Original Image
          </Button>
          {isInProgress && activeDownload === 'original' && (
            <LoaderBar style={{ width: '100%' }} percent={percentage} displayPercent={false} text={null} />
          )}
        </Group>

        {publishedId &&
          downloads.map((downloadGroup: { label: any; sizes: any[] }) => {
            return (
              <>
                <Divider />
                <Wrapper margin={{ all: 'sm' }}>
                  <FormLabel
                    label={downloadGroup.label}
                    labelPosition="top"
                    margin={{ top: 'xs', bottom: 'sm' }}
                    fullWidth
                  >
                    <Group gap="sm" fullWidth margin={{ top: 'xs' }}>
                      {downloadGroup.sizes.map((download: any) => {
                        const url = getImageUrl({
                          image,
                          publishedId,
                          width: download.width,
                          aspect_ratio: download.aspect_ratio,
                          env,
                          property,
                          format: download.format,
                          pixel_ratio: 1,
                        });
                        return (
                          <Group gap="sm" fullWidth key={download.name}>
                            <Button
                              data-testid="download-btn"
                              fullWidth
                              tertiary
                              disabled={isInProgress || disabled}
                              icon={IconDownload}
                              componentEl="a"
                              href={url}
                              onClick={(e: Event) => {
                                e.preventDefault();
                                setActiveDownload(download.name);
                                downloadImage(
                                  url,
                                  `${download.name.replace('#publishedId#', publishedId)}.${download.format}`,
                                );
                              }}
                            >
                              {download.label}
                            </Button>
                            {isInProgress && activeDownload === download.name && (
                              <LoaderBar
                                style={{ width: '100%' }}
                                percent={percentage}
                                displayPercent={false}
                                text={null}
                              />
                            )}
                          </Group>
                        );
                      })}
                    </Group>
                  </FormLabel>
                </Wrapper>
              </>
            );
          })}

        {hasAccess && (
          <>
            <Divider />
            <CompareImageQuality image={image} />
          </>
        )}
      </Dropdown>
    );
  } catch (error) {
    console.error('DownloadButtons', error);
  }
}

export default DownloadButtons;
