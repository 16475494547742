import React, { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import { useAuth, LoadingPage } from '@screentone/addon-auth-wrapper';
import { PageWrapper, FormLabel, Switch } from '@screentone/core';

import 'swagger-ui-react/swagger-ui.css';

import './swagger.module.css';

function DeveloperDocs() {
  const { authState } = useAuth();
  const [swagger, setSwagger] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  useEffect(() => {
    if (!swagger) {
      fetch(`/api/swagger`, { headers: { Authorization: `Bearer ${authState.accessToken.accessToken}` } })
        .then((response) => response.json())
        .then((json) => setSwagger(json))
        .catch((err) => console.error('DeveloperDocs Swagger err: ', err));
    }
  }, [swagger]);

  if (!swagger) {
    return <LoadingPage />;
  }

  return (
    <PageWrapper className={`im_pagewrapper_stacks${authorized ? ' authorized' : ''}`}>
      <FormLabel
        label="Authorize as myself"
        labelPosition="right"
        className={'im-swagger-ui-authorize-self'}
        margin={{ right: 'lg' }}
        style={{ float: 'right' }}
      >
        <Switch onChange={() => setAuthorized(!authorized)} />
      </FormLabel>

      <SwaggerUI
        spec={swagger}
        docExpansion="list"
        requestInterceptor={async (req) => {
          if (authState) {
            const { accessToken } = authState;
            req.headers.authorization = `Bearer ${accessToken.accessToken}`;
          }
          return req;
        }}
      />
    </PageWrapper>
  );
}

export default DeveloperDocs;
