{
  "KEY": "rn",
  "RELATED_PROPERTIES": [],
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": false
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://images-rn.dev.djtools.dowjones.io/"
    },
    "dev": {
      "default": "https://images-rn.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": ""
    },
    "prd": {
      "default": ""
    }
  },
  "EAGER": [],
  "SLACK": {
    "REMOVED_IMAGES": {
      "local": "C02PRBFBBH7",
      "dev": "C02PRBFBBH7",
      "int": "C02PRBFBBH7",
      "prd": "C082P98P9B7"
    }
  },
  "PREVIEW_SIZES": {
    "1:1:thumb": {
      "isThumbnail": true,
      "ar": 1,
      "label": "Thumb 1:1"
    },
    "3:2:thumb": {
      "isThumbnail": true,
      "ar": 1.5,
      "label": "Thumb 3:2"
    },
    "1:1": {
      "ar": 1,
      "label": "1:1"
    },
    "2:1": {
      "ar": 2,
      "label": "2:1"
    },
    "3:2": {
      "ar": 1.5,
      "label": "3:2"
    },
    "5:3": {
      "ar": 1.67,
      "label": "5:3"
    },
    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    },
    "4:5": {
      "ar": 0.8,
      "label": "4:5"
    },
    "2:3": {
      "ar": 0.67,
      "label": "2:3"
    },
    "9:16": {
      "ar": 0.56,
      "label": "9:16"
    }
  },
  "ALT_SIZES": [
    {
      "key": "1:1",
      "label": "1:1",
      "width": 1280,
      "height": 1280
    },
    {
      "key": "2:1",
      "label": "2:1",
      "width": 1280,
      "height": 640
    },
    {
      "key": "3:2",
      "label": "3:2",
      "width": 1280,
      "height": 853
    },
    {
      "key": "16:9",
      "label": "16:9",
      "width": 1280,
      "height": 720
    },
    {
      "key": "4:5",
      "label": "4:5",
      "width": 1280,
      "height": 1600
    },
    {
      "key": "5:3",
      "label": "5:3",
      "width": 2000,
      "height": 1200
    }
  ],
  "DEFAULTS": {},
  "COLORS": [],
  "MD5_4_DOMAIN": {
    "local": "079f",
    "dev": "079f",
    "int": "",
    "prd": ""
  },
  "FORM_VALIDATION": ["headline", "caption", "credit", "datePhotographed", "contact", "slug"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "SEARCH_FIELDS": ["metadata.headline", "metadata.caption", "metadata.credit", "metadata.contact"]
}
