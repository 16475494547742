import React, { useState } from 'react';
import { Input, Group, FormLabel, Button, Typography, IconCaretLeft, Grid, Select } from '@screentone/core';
import { Link } from 'react-router-dom';

import { constants } from '../../../utils';
import { useConfig } from '../../../hooks/useConfig';

import type { PropertyType } from '../../../types';
import CompareImageContent from '../../../components/_temp_CompareImageQuality/CompareImageContent.component';

function DeveloperDocs() {
  const properties = constants.PROPERTY_LABELS;

  const searchParams = new URLSearchParams(location.search);
  const {
    authFetch,
    session: { env },
  } = useConfig();

  const [property, setProperty] = useState((searchParams.get('property') || 'mansionglobal') as PropertyType);
  const [publishedId, setId] = useState(searchParams.get('id') as PropertyType);
  const [image, setImage] = useState();

  const backStyles = {
    zIndex: 'var(--st-zindex-mid)',
    borderBottom: 'var(--st-border-width) solid var(--st-border-color)',
    backgroundColor: 'var(--st-page-bg)',
    padding: 'var(--st-spacer-md) var(--st-spacer-mlg) var(--st-spacer-md) var(--st-spacer-mlg)',
    marginBottom: 'var(--st-spacer-lg)',
  };

  const formSubmit = () => {
    authFetch(`/api/${property}/${publishedId}`)
      .then((img) => {
        setImage(img);
      })
      .catch((error) => {
        return { data: null, error };
      });
  };

  return (
    <>
      {publishedId && property && (
        <div style={backStyles}>
          <Group align="space-between">
            <Button icon={IconCaretLeft} tertiary componentEl={Link} href={`/${property}/image/${publishedId}`}>
              Image Details
            </Button>
          </Group>
        </div>
      )}
      <>
        <Grid>
          <Grid.Row>
            <Grid.Col xs={12}>
              <Typography variant="header1">Image Quality Compare</Typography>
              <Group valign="end" margin={{ bottom: 'md' }}>
                <FormLabel label="Property" labelPosition="top">
                  <Select
                    name="name"
                    value={property}
                    onChange={(event: any) => {
                      setProperty(event.target.value as PropertyType);
                    }}
                  >
                    {Object.keys(properties).map((e) => (
                      <option key={e} value={e}>
                        {properties[e as PropertyType]}
                      </option>
                    ))}
                  </Select>
                </FormLabel>
                <FormLabel label="id" labelPosition="top">
                  <Input value={publishedId} onChange={(event: any) => setId(event.target.value)} />
                </FormLabel>
                <Button primary onClick={() => formSubmit()}>
                  Submit
                </Button>
              </Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12}>
              {image && <CompareImageContent image={image} publishedId={publishedId} env={env} />}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </>
    </>
  );
}

export default DeveloperDocs;
