import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Group,
  IconImage,
  IconEdit,
  IconSliders,
  IconExternalLink,
  useModalState,
  Dialog,
  Typography,
  useModalPortal,
  IconCheckCircle,
} from '@screentone/core';
import { NavLink, useParams, useNavigate } from 'react-router-dom';

import useImageDetail from '../../../hooks/useImageDetail';
import useConfig from '../../../hooks/useConfig';
import { constants } from '../../../utils';
import useEditImage from '../../../hooks/useEditImage';
import useAlert from '../../../hooks/useAppAlerts';

function PanelHeader() {
  const { mode, im: publishedIdFromUrl, type: pageType } = useParams();
  const isBatchFlow = pageType === 'batch';
  const { DETAILS, METADATA, EDIT } = constants.IMAGE_PAGE_MODE;
  const pageMode = constants.IMAGE_PAGE_MODE[mode?.toUpperCase() as keyof typeof constants.IMAGE_PAGE_MODE] || DETAILS;
  const canEditImage = !publishedIdFromUrl;
  const { image } = useImageDetail();
  const {
    session: { property },
  } = useConfig();

  const [tabId, setTab] = useState(pageMode);
  const { isDirty, publishData, resetData, isSaving, setIsSaving } = useEditImage();
  const { setAlert } = useAlert();
  const { open, openModal, closeModal } = useModalState();
  const navigate = useNavigate();

  useEffect(() => {
    setTab(pageMode);
  }, [pageMode]);

  const { renderNode } = useModalPortal();

  return (
    <Box padding={{ all: 'sm' }}>
      <Group gap="sm">
        {!isBatchFlow && (
          <Button
            data-testid="img-details-btn"
            icon={IconImage}
            tertiary
            active={tabId === DETAILS}
            onClick={() => {
              if (isDirty()) {
                openModal();
              } else {
                setTab(DETAILS);
                navigate(
                  `/${property}/${pageType}/${image.asset_id}${
                    publishedIdFromUrl ? `/${DETAILS}/${publishedIdFromUrl}` : ''
                  }`,
                );
              }
            }}
            replace
          >
            Image Details
          </Button>
        )}

        {image?.isChart ? (
          <Button
            data-testid="view-chart-btn"
            tertiary
            icon={IconExternalLink}
            componentEl="a"
            target="_blank"
            rel="noopener noreferrer"
            title="external link to Chartlos"
            href={`https://${image?.metadata?.import_source}/${property}/chart/${image?.metadata?.import_source_id}`}
          >
            View in Charts
          </Button>
        ) : (
          <>
            {(canEditImage || image?.isDynamic) && (
              <Button
                data-testid="edit-metadata-btn"
                icon={IconEdit}
                componentEl={NavLink}
                to={`/${property}/${pageType}/${image.asset_id}/metadata${
                  publishedIdFromUrl ? `/${publishedIdFromUrl}` : ''
                }`}
                tertiary
                active={tabId === METADATA}
                onClick={() => {
                  setTab(METADATA);
                }}
                replace
              >
                {image?.isDynamic ? '' : 'Edit'} Metadata
              </Button>
            )}
            {(!canEditImage || image?.isDynamic) && (
              <Button
                data-testid="edit-img-btn"
                icon={IconSliders}
                componentEl={NavLink}
                to={`/${property}/${pageType}/${image.asset_id}/edit/${publishedIdFromUrl}`}
                tertiary
                active={tabId === EDIT}
                onClick={() => {
                  setTab(EDIT);
                }}
                replace
              >
                {image?.isDynamic ? '' : 'Edit'} Image
              </Button>
            )}
          </>
        )}

        {open && (
          <Dialog renderNode={renderNode} status={open ? 'open' : 'closed'} onDismiss={closeModal} size="fluid">
            <Dialog.Title>Edit Image</Dialog.Title>
            <Dialog.Content>
              <Typography margin={{ top: 'md' }}>
                Do you want to save pending changes before leaving this page?
              </Typography>
            </Dialog.Content>
            <Dialog.Actions>
              <Button
                secondary
                type="reset"
                disabled={isSaving}
                onClick={() => {
                  setTab(DETAILS);
                  navigate(
                    `/${property}/${pageType}/${image.asset_id}${
                      publishedIdFromUrl ? `/${DETAILS}/${publishedIdFromUrl}` : ''
                    }`,
                  );
                  resetData();
                  closeModal();
                }}
              >
                Continue without saving
              </Button>
              <Button
                primary
                disabled={isSaving}
                onClick={() => {
                  setIsSaving(true);
                  publishData()
                    .then(() => {
                      setIsSaving(false);
                      setTab(DETAILS);
                      navigate(
                        `/${property}/${pageType}/${image.asset_id}${
                          publishedIdFromUrl ? `/${DETAILS}/${publishedIdFromUrl}` : ''
                        }`,
                      );
                      setAlert('Image updated', { type: 'success', icon: IconCheckCircle });
                      closeModal();
                    })
                    .catch(() => {
                      setIsSaving(false);
                      console.error('Failed to save image');
                      setAlert('Failed to save image', { type: 'error' });
                    });
                }}
              >
                Save and continue
              </Button>
            </Dialog.Actions>
          </Dialog>
        )}
      </Group>
    </Box>
  );
}

export default PanelHeader;
