import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Group,
  Typography,
  IconCheckCircle,
  IconError,
  Dropdown,
  useDropdownState,
  IconCaretDown,
  FormLabel,
  IconThumbsDown,
  IconThumbsUp,
} from '@screentone/core';
import { ErrorMessage, LoadingPage, useAuth } from '@screentone/addon-auth-wrapper';

import useConfig from '../../hooks/useConfig';
import { formatBytes, getMD5Domain, getCldTransformationImage } from '../../utils/helpers';

import type { EnvironmentType, ImageType } from '../../types';

type CompareImageContentTypes = {
  /** object representing image's data */
  image: ImageType;
  publishedId?: string;
  env?: EnvironmentType;
};

function CompareImageContent({ image: prodImage, publishedId, env: passedEnv }: CompareImageContentTypes) {
  const [optimizedImage, setOptimizedImage] = useState<null | ImageType>(null);
  const [optimizedDomain, setOptimizedDomain] = useState<null | string>(null);
  const [prodDomain, setProdDomain] = useState<null | string>(null);

  const imageWidths = [
    {
      label: 'Thumbnail',
      width: 300,
    },
    {
      label: 'Article',
      width: 620,
    },
    {
      label: 'Large',
      width: 944,
    },
  ];

  const [imageWidth, setImageWidth] = useState(620);

  const [loadingError, setLoadingError] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedbackError, setFeedbackError] = useState(false);

  const [optimizedUrl, setOptimizedUrl] = useState<string | null>(null);
  const [optimizedBestUrl, setOptimizedBestUrl] = useState<string | null>(null);
  const [prodUrl, setProdUrl] = useState<string | null>(null);
  const [scaledOriginalUrl, setScaledOriginalUrl] = useState<string | null>(null);
  const [originalUrl, setOriginalUrl] = useState<string | null>(prodImage.secure_url);

  const [optimizedData, setOptimizedData] = useState<{} | null>(null);
  const [optimizedBestData, setOptimizedBestData] = useState<{} | null>(null);
  const [scaledOriginalData, setScaledOriginalData] = useState<{} | null>(null);

  useEffect(() => {
    if (optimizedUrl) {
      fetch(optimizedUrl.replace(`/${property}`, `/fl_getinfo/${property}`))
        .then((response) => response.json())
        .then((data) => {
          console.log('optimizedUrl File Size : ', formatBytes(data?.output?.bytes));
          console.log('optimizedUrl: ', data);
          setOptimizedData(data);
        });
    }
  }, [optimizedUrl]);
  useEffect(() => {
    if (optimizedBestUrl) {
      fetch(optimizedBestUrl.replace(`/${property}`, `/fl_getinfo/${property}`))
        .then((response) => response.json())
        .then((data) => {
          console.log('optimizedBestUrl File Size : ', formatBytes(data?.output?.bytes));
          console.log('optimizedBestUrl: ', data);
          setOptimizedBestData(data);
        });
    }
  }, [optimizedBestUrl]);
  useEffect(() => {
    if (scaledOriginalUrl) {
      fetch(scaledOriginalUrl.replace(`/${property}`, `/fl_getinfo/${property}`))
        .then((response) => response.json())
        .then((data) => {
          console.log('scaledOriginalUrl File Size : ', formatBytes(data?.output?.bytes));
          console.log('scaledOriginalUrl: ', data);
          setScaledOriginalData(data);
        });
    }
  }, [scaledOriginalUrl]);

  const [dropdownAlgorithm, setDropdownAlgorithm] = useState('Optimized');

  const [originalSliderLoaded, setOriginalSliderLoaded] = useState(0);
  const [qualitySliderLoaded, setQualitySliderLoaded] = useState(0);
  const originalSliderStyle = {
    opacity: originalSliderLoaded === 2 ? 1 : 0,
    transition: 'opacity 1s 0.5s ease-in-out',
  };
  const qualitySliderStyle = {
    opacity: qualitySliderLoaded === 2 ? 1 : 0,
    transition: 'opacity 1s 0.5s ease-in-out',
  };

  const { open, setOpen, componentRef } = useDropdownState();
  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const {
    open: imageWidthDropdownIsOpen,
    setOpen: setImageWidthDropdownIsOpen,
    componentRef: imageWidthDropdownComponentRef,
  } = useDropdownState();
  const handleImageWidthDropdownClick = useCallback(() => {
    setImageWidthDropdownIsOpen(!imageWidthDropdownIsOpen);
  }, [setImageWidthDropdownIsOpen, imageWidthDropdownIsOpen]);

  const {
    authFetch,
    session: { env: sessionEnv, property },
  } = useConfig();
  const env = passedEnv || sessionEnv;

  const { userAccess } = useAuth();
  const isAdmin = userAccess('app_admin');
  const isDeveloper = userAccess('developer');

  useEffect(() => {
    // console.log('useEffect: ');
    if (!optimizedImage) {
      // console.log('authFetch: ');
      authFetch(`/api/services/compare/:property/${prodImage.asset_id}`)
        .then((res) => {
          setOptimizedImage(res.response.optimizedImage);
          setOptimizedDomain(res.response.optimizedDomain);
          setProdDomain(res.response.prodDomain);
        })
        .catch((err) => {
          console.error('compare err: ', err);
          setLoadingError(true);
        });
    }
  }, []);

  useEffect(() => {
    if (prodDomain && optimizedImage && optimizedDomain && !optimizedUrl) {
      const fnSelectsOptions = {
        image: prodImage,
        width: imageWidth,
        host: getMD5Domain(env, property),
        publishedId,
        format: 'avif',
        pixel_ratio: window?.devicePixelRatio || 1,
      };
      const cldFnSelects = getCldTransformationImage(fnSelectsOptions);

      const fnSelectsOriginalOptions = {
        image: prodImage,
        width: imageWidth,
        host: getMD5Domain(env, property),
        format: prodImage.format,
        pixel_ratio: window?.devicePixelRatio || 1,
      };
      const cldFnSelectsOriginal = getCldTransformationImage(fnSelectsOriginalOptions);

      setOptimizedUrl(`${optimizedDomain}image/upload/${cldFnSelects.transformation}/${optimizedImage.public_id}`);
      setScaledOriginalUrl(
        `${optimizedDomain}image/upload/${cldFnSelectsOriginal.transformation.replace(
          ',q_auto:eco',
          ',q_100',
        )}/fl_original/${optimizedImage.public_id}`,
      );
      setOptimizedBestUrl(
        `${optimizedDomain}image/upload/${cldFnSelects.transformation.replace('q_auto:eco', 'q_auto:best')}/${
          optimizedImage.public_id
        }`,
      );
      setProdUrl(`${prodDomain}image/upload/${cldFnSelects.transformation}/${prodImage.public_id}`);

      setOriginalUrl(prodImage.secure_url);
    }
  }, [imageWidth, optimizedDomain, prodDomain]);

  if (!loadingError) {
    <Alert icon={IconError} type="error" margin={{ bottom: 'md' }}>
      <ErrorMessage message={'There was an error sending your feedback. '} />
    </Alert>;
  }
  if (!optimizedImage) {
    return <LoadingPage />;
  }

  const sendFeedback = async (improved: boolean, type: string, feedback?: string) => {
    let imageData = {};
    if (type === 'optimized') {
      imageData = {
        label: 'Optimized',
        url: optimizedUrl,
        dpr: window?.devicePixelRatio,
        input: optimizedData?.input || 'n/a',
        g_auto_info: optimizedData?.g_auto_info || 'n/a',
        resize: optimizedData?.resize || 'n/a',
        output: optimizedData?.output || 'n/a',
      };
    } else if (type === 'optimizedBest') {
      imageData = {
        label: 'Optimized Best',
        url: optimizedBestUrl,
        dpr: window?.devicePixelRatio,
        input: optimizedBestData?.input || 'n/a',
        g_auto_info: optimizedBestData?.g_auto_info || 'n/a',
        resize: optimizedBestData?.resize || 'n/a',
        output: optimizedBestData?.output || 'n/a',
      };
    } else if (type === 'scaledOriginal') {
      imageData = {
        label: 'Scaled Original',
        url: scaledOriginalUrl,
        dpr: window?.devicePixelRatio,
        input: scaledOriginalData?.input || 'n/a',
        g_auto_info: scaledOriginalData?.g_auto_info || 'n/a',
        resize: scaledOriginalData?.resize || 'n/a',
        output: scaledOriginalData?.output || 'n/a',
      };
    }

    // console.log('sendFeedback: ', improved, feedback);
    // console.log('window.screen: ', window.screen);
    const screenDetails = await window.getScreenDetails();
    // console.log('screenDetails: ', screenDetails);
    const body = {
      improved,
      feedback,
      imageData,
      imageWidth,
      imageUrl: originalUrl,
      screen: screenDetails.screens.map((screen) => ({
        availHeight: screen.availHeight,
        availLeft: screen.availLeft,
        availTop: screen.availTop,
        availWidth: screen.availWidth,
        colorDepth: screen.colorDepth,
        devicePixelRatio: screen.devicePixelRatio,
        height: screen.height,
        isExtended: screen.isExtended,
        isInternal: screen.isInternal,
        isPrimary: screen.isPrimary,
        label: screen.label,
        orientation: screen.orientation.type,
        pixelDepth: screen.pixelDepth,
        width: screen.width,
      })),
      currentScreen: screenDetails.currentScreen.label,
    };
    // console.log('body: ', body);
    setSendingFeedback(true);
    authFetch(`/api/services/compare/:property/${prodImage.asset_id}/feedback`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then((res) => {
        console.log('res: ', res);
        setShowTextArea(false);
        setFeedbackSent(true);
      })
      .catch((err) => {
        console.error('compare feedback err: ', err);
        setFeedbackError(true);
      });
  };

  return (
    <div key={`${dropdownAlgorithm}_${imageWidth}`}>
      {feedbackSent && (
        <Alert icon={IconCheckCircle} type="success" margin={{ bottom: 'md' }} autoDismiss>
          Feedback sent successfully Thank you for your feedback
        </Alert>
      )}
      {feedbackError && (
        <Alert icon={IconError} type="error" margin={{ bottom: 'md' }} autoDismiss>
          <ErrorMessage message={'There was an error sending your feedback. '} />
        </Alert>
      )}
      <FormLabel label="Image Width:" labelPosition="left">
        <Dropdown
          trigger={
            <Group gap="xs" wrap={false}>
              {imageWidths.find((item) => item.width === imageWidth)?.label}
              <IconCaretDown />
            </Group>
          }
          open={imageWidthDropdownIsOpen}
          onToggle={handleImageWidthDropdownClick}
          componentRef={imageWidthDropdownComponentRef}
          padding={{ all: 'none' }}
        >
          {imageWidths.map((item) => (
            <Button
              style={{ width: '200px' }}
              tertiary
              fullWidth
              active={imageWidth === item.width}
              onClick={() => {
                setOptimizedUrl(null);
                setOptimizedBestUrl(null);
                setProdUrl(null);
                setOriginalSliderLoaded(0);
                setQualitySliderLoaded(0);
                setImageWidth(item.width);
                setOpen(!imageWidthDropdownIsOpen);
              }}
            >
              {item.label}
            </Button>
          ))}
        </Dropdown>
      </FormLabel>
      <Group>
        <Group.Item style={{ maxWidth: `${imageWidth}px` }} data-debug={imageWidth}>
          {originalUrl && (
            <figure style={{ margin: 'var(--st-spacer-md) 0' }}>
              <Typography componentEl="figcaption" variant="header4" align="left">
                Original
              </Typography>
              <img
                style={{ backgroundColor: 'var(--st-color-blurple-10)' }}
                src={originalUrl}
                alt={originalUrl}
                title="Original Image"
                width={imageWidth}
                data-id="CompareImageContentOptimized"
              />
            </figure>
          )}
        </Group.Item>
        <Group.Item style={{ maxWidth: `${imageWidth}px` }} data-debug={imageWidth}>
          {optimizedUrl && optimizedData && (
            <figure style={{ margin: 'var(--st-spacer-md) 0' }}>
              <Group align="space-between" componentEl="figcaption">
                <Typography inline variant="header4" align="left">
                  Optimized
                </Typography>
                <Group.Item>
                  <Button
                    tertiary
                    color="emerald"
                    onClick={() => sendFeedback(true, 'optimized')}
                    disabled={sendingFeedback}
                    icon={IconThumbsUp}
                  />
                  <Button
                    tertiary
                    color="lava"
                    onClick={() => sendFeedback(false, 'optimized')}
                    disabled={sendingFeedback}
                    icon={IconThumbsDown}
                  />
                </Group.Item>
              </Group>
              <img
                style={{ backgroundColor: 'var(--st-color-blurple-10)' }}
                src={optimizedUrl}
                alt={optimizedUrl}
                title="Optimized Image"
                width={imageWidth}
                data-id="CompareImageContentOptimized"
              />
            </figure>
          )}
        </Group.Item>
        <Group.Item style={{ maxWidth: `${imageWidth}px` }} data-debug={imageWidth}>
          {optimizedBestUrl && optimizedBestData && (
            <figure style={{ margin: 'var(--st-spacer-md) 0' }}>
              <Group align="space-between" componentEl="figcaption">
                <Typography inline variant="header4" align="left">
                  Optimized Best
                </Typography>
                <Group.Item>
                  <Button
                    tertiary
                    color="emerald"
                    onClick={() => sendFeedback(true, 'optimizedBest')}
                    disabled={sendingFeedback}
                    icon={IconThumbsUp}
                  />
                  <Button
                    tertiary
                    color="lava"
                    onClick={() => sendFeedback(false, 'optimizedBest')}
                    disabled={sendingFeedback}
                    icon={IconThumbsDown}
                  />
                </Group.Item>
              </Group>
              <img
                style={{ backgroundColor: 'var(--st-color-blurple-10)' }}
                src={optimizedBestUrl}
                alt={optimizedBestUrl}
                title="Optimized Best Image"
                width={imageWidth}
                data-id="CompareImageContentOptimizedBest"
              />
            </figure>
          )}
        </Group.Item>
        <Group.Item style={{ maxWidth: `${imageWidth}px` }} data-debug={imageWidth}>
          {scaledOriginalUrl && scaledOriginalData && (
            <figure style={{ margin: 'var(--st-spacer-md) 0' }}>
              <Group align="space-between" componentEl="figcaption">
                <Typography inline variant="header4" align="left">
                  Scaled Original
                </Typography>
                <Group.Item>
                  <Button
                    tertiary
                    color="emerald"
                    onClick={() => sendFeedback(true, 'scaledOriginal')}
                    disabled={sendingFeedback}
                    icon={IconThumbsUp}
                  />
                  <Button
                    tertiary
                    color="lava"
                    onClick={() => sendFeedback(false, 'scaledOriginal')}
                    disabled={sendingFeedback}
                    icon={IconThumbsDown}
                  />
                </Group.Item>
              </Group>
              <img
                style={{ backgroundColor: 'var(--st-color-blurple-10)' }}
                src={scaledOriginalUrl}
                alt={scaledOriginalUrl}
                title="Scaled Original Image"
                width={imageWidth}
                data-id="CompareImageContentScaledOriginal"
              />
            </figure>
          )}
        </Group.Item>
      </Group>
    </div>
  );
}

export default CompareImageContent;
