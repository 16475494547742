import React from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Sidebar, PageWrapper, Typography } from '@screentone/core';

import SidebarComponent from './components/Sidebar';

import Home from './';

import API from './api';
import API_Swagger from './api/swagger';
import API_m2m from './api/m2m-authentication';
import API_uploads from './api/third-party';

import Image_Domains from './images/domains';
import Image_URL from './images/url';

import iFrame from './iframe';
import iFrame_Example from './iframe/example';

import Snippety from './snippety';

import Debugger_Renderer from './debugger/renderer';
import Debugger_Compare from './debugger/compare';
import Debugger_Social from './debugger/social';
import Debugger_UserSearchLogs from './debugger/UserSearchLogs';

import AI_GenerativeFill from './ai/generative-fill';
import AI_ObjectRemoval from './ai/object-removal';
import AI_Resolution from './ai/resolution';

type Route = {
  route: string;
  page: React.FC;
  label: string;
  attributes?: any;
  fullWidth?: boolean;
};
type Section = {
  label: string;
  routes: Route[];
}[];

// To add a new developer route, add it to an existing section sections in the routes array
// or create a new section with a label and routes array. The routes array should contain
// an object with the route, page, and label properties.
// This will automatically generate the sidebar navigation and the routes for the page.
// The page component should be imported at the top of the file.

const sections: Section = [
  {
    label: 'Home',
    routes: [{ route: '/developer', page: Home, label: 'Developer Home' }],
  },
  {
    label: 'Images',
    routes: [
      { route: '/developer/images/domains', page: Image_Domains, label: 'Domains' },
      { route: '/developer/images/url', page: Image_URL, label: 'URL Structure' },
    ],
  },
  {
    label: 'iFrame',
    routes: [
      { route: '/developer/iframe', page: iFrame, label: 'Integration', attributes: { 'data-testid': 'iframe' } },
      {
        route: '/developer/iframe/example',
        page: iFrame_Example,
        label: 'Example',
        attributes: { 'data-testid': 'example-btn' },
      },
    ],
  },
  {
    label: 'Snippety',
    routes: [{ route: '/developer/snippety', page: Snippety, label: 'Integration' }],
  },
  {
    label: 'API',
    routes: [
      { route: '/developer/api', page: API, label: 'Integration' },
      { route: '/developer/api/m2m-authentication', page: API_m2m, label: 'M2M Authentication' },
      { route: '/developer/api/access', page: API_uploads, label: 'Third Party Endpoints' },
      { route: '/developer/api/swagger', page: API_Swagger, label: 'REST Endpoints' },
    ],
  },
  {
    label: 'Debugger',
    routes: [
      { route: '/developer/debugger/renderer', page: Debugger_Renderer, label: 'FN Selects Renderer' },
      { route: '/developer/debugger/social', page: Debugger_Social, label: 'Watermarking' },
      { route: '/developer/debugger/compare', page: Debugger_Compare, label: 'Compare', fullWidth: true },
      { route: '/developer/debugger/userSearchLogs', page: Debugger_UserSearchLogs, label: 'User Search Logs' },
    ],
  },
  {
    label: 'Cloudinary AI',
    routes: [
      { route: '/developer/ai/resolution', page: AI_Resolution, label: 'Improve Image Resolution' },
      { route: '/developer/ai/generative-fill', page: AI_GenerativeFill, label: 'Generative Fill' },
      { route: '/developer/ai/object-removal', page: AI_ObjectRemoval, label: 'Object Removal' },
    ],
  },
];

const routes: Route[] = sections.reduce((acc: Route[], { routes }: { routes: Route[] }) => [...acc, ...routes], []);

const Page = () => {
  const { pathname } = useLocation();

  const pageInfo = routes.find(({ route }) => route === pathname.replace(/\/$/, ''));
  let Page = pageInfo?.page || Home;
  console.log('Page: ', Page);

  return (
    <>
      <SidebarComponent sections={sections} />
      <Sidebar.Page padding={{ bottom: 'mlg' }}>
        <PageWrapper margin={{ vertical: 'lg' }} style={pageInfo?.fullWidth ? { maxWidth: 'none' } : {}}>
          <Page />
          <Divider />
          <Typography variant="note" margin={{ top: 'md', left: 'sm' }}>
            If you have any questions, please reach out to the{' '}
            <Typography
              variant="link"
              href="https://app.slack.com/client/T025QN6JG/CM49J817F"
              target="_blank"
              rel="noreferrer"
              inline
            >
              Image Manager team on our dev slack channel
            </Typography>
          </Typography>
        </PageWrapper>
      </Sidebar.Page>
    </>
  );
};

const DeveloperRoutes = routes.map(({ route }) => {
  return {
    path: route,
    component: Page,
    protected: true,
    restricted: ['developer'],
  };
});

export default DeveloperRoutes;
export { DeveloperRoutes };
