{
  "KEY": "wsjcommerce",
  "INITIAL_QUERY_PARAMS": {
    "oneTimeUse": false
  },
  "IMAGE_DOMAINS": {
    "local": {
      "default": "https://dj-development-res.cloudinary.com/"
    },
    "dev": {
      "default": "https://images-buyside.dev.djtools.dowjones.io/"
    },
    "int": {
      "default": "https://images.stg.wsjcommerce.net/"
    },
    "prd": {
      "default": "https://images.wsjcommerce.net/"
    }
  },
  "PREVIEW_SIZES": {
    "1:1": {
      "isThumbnail": true,
      "ar": 1,
      "label": "1:1",
      "w": 300
    },
    "16:9": {
      "ar": 1.78,
      "label": "16:9"
    },
    "2:1:thumb": {
      "isThumbnail": true,
      "ar": 2.1,
      "label": "Thumb 2:1"
    },
    "wsj:1:1:thumb": {
      "isThumbnail": true,
      "label": "WSJ Thumb",
      "ar": 1.5,
      "w": 287,
      "h": 191
    },
    "wsj:3:2": {
      "label": "WSJ 3:2",
      "ar": 1.5,
      "w": 607,
      "h": 405
    }
  },
  "EAGER": [],
  "SLACK": {
    "REMOVED_IMAGES": {
      "local": "C02PRBFBBH7",
      "dev": "C02PRBFBBH7",
      "int": "C02PRBFBBH7",
      "prd": "C03D46FEYN9"
    }
  },
  "ALT_SIZES": [
    {
      "key": "horizontal",
      "label": "16:9",
      "width": 940,
      "height": 529
    },
    {
      "key": "square",
      "label": "Square",
      "width": 300,
      "height": 300
    }
  ],
  "DEFAULTS": {
    "GRAPHIC_TYPE": "Commerce"
  },
  "COLORS": [
    "#D2E5F1",
    "#6AA1C8",
    "#3480BE",
    "#D0E5E1",
    "#52827D",
    "#336666",
    "#F7DADA",
    "#C16B68",
    "#7C3936",
    "#FBECDA",
    "#F8D284",
    "#A3603D",
    "#DBDAEF",
    "#9C9DD1",
    "#4A466C"
  ],
  "MD5_4_DOMAIN": {
    "local": "7e3e",
    "dev": "7e3e",
    "int": "e5a0",
    "prd": "e9e6"
  },
  "FORM_VALIDATION": ["headline"],
  "CROP": [
    {
      "label": "16:9",
      "ar": 1.78
    },
    {
      "label": "3:2",
      "ar": 1.5
    }
  ],
  "CLD_SOURCES": {
    "uploaded": {
      "label": "Uploaded",
      "folder": ":property/cld",
      "subSources": {
        "legacy": {
          "label": "Legacy",
          "folder": ":property/smartcrop"
        },
        "dynamic": {
          "label": "Dynamic",
          "folder": ":property/dynamic"
        }
      }
    }
  },
  "SEARCH_FIELDS": ["metadata.caption", "metadata.credit", "metadata.contact", "context.\"tags_$PROPERTY$\""]
}
