import { User } from '@screentone/addon-auth-wrapper';
import { format } from '@screentone/addon-calendar';
import ExifReader from 'exifreader';

import { constants, helpers } from '../../../utils';
import UseUploadType, { Files } from '../types';
import type { PropertyType } from '../../../types';
import { UniqueImageResponseObject } from '../../../types/uniqueImageResponse';
import { slugNormalize } from '../../../utils/helpers';

type UploadTypeType = 'dynamic' | 'single';

const loadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      return resolve({ 'Image Width': { value: img.width }, 'Image Height': { value: img.height } });
    };
    img.onerror = reject;
    img.src = src;
  });
};

/**
 * Given an image file, extract the EXIF data from the file and handle any possible errors
 */
const parseImageData = async (file: File, url: string) => {
  // This array is used to add image formats that the ExifReader library doesn't support, so we can avoid showing the warning message.

  // TODO: this should change to a supported list of image formats
  const typesNotAllowed = ['gif', 'svg+xml'];
  const currentFileType = file?.type.split('/')[1];
  try {
    if (typesNotAllowed.includes(currentFileType)) {
      const metadata = await loadImage(url);
      return metadata;
    }
    const metadata = await ExifReader.load(file);
    return metadata;
  } catch (error) {
    console.error('error: ', error);
    return null;
  }
};

/**
 * Extract and format the date from an image's metadata
 */
const handleDates = (tags: any) => {
  const parsedDate = helpers.parseDate(
    tags?.DateCreated?.description ||
      tags?.['Date Created']?.description ||
      tags?.CreateDate?.description ||
      tags?.DateTime?.description ||
      tags?.DateTimeOriginal?.description ||
      tags?.DateTimeDigitized?.description,
  );

  let formattedDate = '';
  try {
    formattedDate = format(parsedDate, constants.DATE_FORMATS.CLOUDINARY);
  } catch (err) {} /* eslint-disable-line no-empty */

  return formattedDate;
};

/**
 * Get Category value from metadata
 */
const handleCategory = (category: string) => {
  if (category && ['s', 'sport'].includes(category.toLowerCase())) {
    return 'Sport';
  } else if (category && ['e', 'entertainment'].includes(category.toLowerCase())) {
    return 'Entertainment';
  } else {
    return category || '';
  }
};

/**
 * Extract and format the keywords from an image's metadata
 */
const handleKeywords = (tags: any) => {
  let keywords: string[] = [];

  if (Array.isArray(tags?.Keywords)) {
    keywords = tags.Keywords.map(({ description }: { description: string }) => description);
  } else if (typeof tags?.Keywords?.description === 'string') {
    keywords = [tags.Keywords.description];
  }

  return keywords.filter((keyword) => !!keyword);
};

/**
 * Given an array of image files, parse each image file, extract the metadata, format that data and
 * apply it to the file
 */
const formatImageFiles = async (acceptedFiles: File[], user: User, property: PropertyType, type: UploadTypeType) => {
  const formattedFiles = acceptedFiles.map(async (file: File) => {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const secure_url = URL.createObjectURL(file);
    const tags: any = await parseImageData(file, secure_url);
    const renderData = {
      height: tags?.['Image Height']?.value || tags?.PixelYDimension?.value,
      secure_url,
      width: tags?.['Image Width']?.value || tags?.PixelXDimension?.value,
    };

    const regex = /@[\d]x.[a-z]{2,4}$/gi;
    const xScale = file.name.match(regex);
    const metadata =
      type === 'dynamic'
        ? {
            caption: '',
            category: '',
            city: '',
            contact: helpers.formatUserName(user?.name || ''),
            country: '',
            credit: '',
            datePhotographed: handleDates(tags),
            graphicType: constants.GRAPHIC_TYPES.includes(tags?.GraphicType?.description as string)
              ? tags?.GraphicType?.description
              : helpers.getDefaultValues(property, 'DYNAMIC_GRAPHIC_TYPE'),
            headline: tags?.Headline?.description || file.name || '',
            keywords: handleKeywords(tags),
            oneTimeUse: false,
            resizeOnly: false,
            specialInstructions: tags?.Instructions?.description || tags?.SpecialInstructions?.description || '',
            state: '',
            importSourceName: file.name.split('.')[0],

            subCategories: '',
            xScale: Number(xScale?.[0].split('.')?.[0]?.replace('@', '').replace('x', '')) || 1,
            object_name: tags?.ObjectName?.description || tags?.['Object Name']?.description || null,
            transmission_reference: tags?.TransmissionReference?.description || null,
          }
        : {
            slug: slugNormalize(file.name.split('.')[0].slice(0, 32)),
            caption: tags?.Caption?.description || tags?.['Caption/Abstract']?.description || '',
            category: handleCategory(tags?.Category?.description),
            city: tags?.City?.description || '',
            contact: tags?.Contact?.description || helpers.formatUserName(user?.name || ''),
            country: tags?.Country?.description || tags?.['Country/PrimaryLocationName']?.description || '',
            credit: tags?.PublishedCredit?.description || tags?.Credit?.description || '',
            datePhotographed: handleDates(tags),
            graphicType: constants.GRAPHIC_TYPES.includes(tags?.GraphicType?.description as string)
              ? tags?.GraphicType?.description
              : helpers.getDefaultValues(property, 'GRAPHIC_TYPE'),
            headline: tags?.Headline?.description || tags?.title?.description || file.name || '',
            keywords: handleKeywords(tags),
            oneTimeUse: false,
            resizeOnly: false,
            specialInstructions: tags?.Instructions?.description || tags?.SpecialInstructions?.description || '',
            state: tags?.State?.description || tags?.['Province/State']?.description || '',
            import_source_name: file.name.split('.')[0],
            import_source_type: 'upload',
            subCategories: tags?.SupplementalCategories?.description || '',
            unique_id: tags?.UniqueID?.description || '',
            object_name: tags?.ObjectName?.description || tags?.['Object Name']?.description || null,
            transmission_reference: tags?.TransmissionReference?.description || null,
          };

    return [renderData, metadata];
  });

  return Promise.all(formattedFiles).then((data) =>
    data.reduce(
      (total: any[][], [renderData, metadata]) => {
        total[0].push(renderData);
        total[1].push(metadata);

        return total;
      },
      [[], []],
    ),
  );
};

/**
 * Given an array of rejected files, reformat some of the error messages to make them more readable
 */
const formatErrorMessages = (rejectedFiles: Files.Rejected) => {
  let rejected = rejectedFiles;

  // alter the message for file too large errors to be more human readable
  rejected = rejected.map((reject) => {
    if (reject.errors[0].code === 'file-too-large') {
      return {
        file: reject.file,
        errors: [
          {
            code: reject.errors[0].code,
            message: `File is larger than ${helpers.formatBytes(constants.MAX_FILE_SIZE)}`,
          },
        ],
      };
    }

    return reject;
  });

  return rejected;
};

type ProcessDroppedFilesParams = {
  /** an array of images that the user intends to upload to Cloudinary */
  acceptedFiles: Files.Accepted;
  /** a number representing the number of files that are currently accepted */
  currentNumberOfAcceptedFiles: number;
  /** a array of images that were rejected by the uploader */
  rejectedFiles: Files.Rejected;
  /** user object from useAuth */
  user: User;
  /** Current Property */
  property: PropertyType;
  /** max number of images allowed to be uploaded */
  maxImages: number;
  /** upload type */
  uploadType: UploadTypeType;
  authFetch: (resource: string, options?: RequestInit | undefined) => Promise<any>;
};

/**
 * Given an array of accepted files and an array of rejected files, preform extra validation, parse
 * the metadata and generally format the data to put onto state in the useUpload hook
 */
const processDroppedFiles = async ({
  acceptedFiles,
  currentNumberOfAcceptedFiles,
  rejectedFiles,
  user,
  property,
  maxImages,
  uploadType,
  authFetch,
}: ProcessDroppedFilesParams) => {
  const accepted: Files.Accepted = [];
  const rejected: Files.Rejected = [];
  const renderData: UseUploadType.Render.State = [];
  const metadata: UseUploadType.Metadata.State = [];

  const fetchByUniqueId = async (
    metadata: UseUploadType.Metadata.Metadata,
  ): Promise<UniqueImageResponseObject | null> => {
    const uniqueIdToSend = metadata.unique_id;
    const { object_name, transmission_reference } = metadata;
    if (uniqueIdToSend) {
      try {
        const data: UniqueImageResponseObject = await authFetch(
          `/api/:property/findByUniqueId/${uniqueIdToSend}?objectName=${object_name}&transmissionReference=${transmission_reference}`,
        );
        return data;
      } catch (error) {
        console.error('error:', error);
      }
    }
    return null;
  };
  let totalFilesLength = currentNumberOfAcceptedFiles + accepted.length;
  let i = 0;

  // process accepted files in batches
  while (totalFilesLength < maxImages && i < 10) {
    const filesUntilLimit = maxImages - totalFilesLength;
    const acceptedBatch = acceptedFiles.slice(i, filesUntilLimit);
    /* eslint-disable-next-line no-await-in-loop */
    const [renderDataBatch, metadataBatch] = await formatImageFiles(acceptedBatch, user, property, uploadType);
    const rejectedBatch = formatErrorMessages(rejectedFiles);

    /* eslint-disable-next-line no-plusplus */
    for (let j = 0; j < renderDataBatch.length; j++) {
      // only add image if it does not exceed max pixel limit
      // NOTE: some images may not have width and height (e.g. gifs), just let them pass
      // in all likelihood, gifs with dimensions that are too large would exceed the minimum file size
      // if they do manage to get past this check, they will fail to upload and the user will be notified
      if ((renderDataBatch[j].width * renderDataBatch[j].height || 0) <= constants.MAX_PIXELS) {
        accepted.push(acceptedBatch[j]);
        renderData.push(renderDataBatch[j]);
        metadata.push(metadataBatch[j]);

        // if image does exceed max pixel limit, add to rejected
      } else {
        rejected.push({
          file: acceptedBatch[j],
          errors: [
            {
              code: 'max-pixels',
              message: `File exceeds max pixels (width * height > ${constants.MAX_PIXELS})`,
            },
          ],
        });
      }

      /**
       * This block of code execute one by one and is responsible for processing an error that occurs when uploading an image that's already in the system.
       * It fetches data for each file based on its unique ID and checks if the file already exists in the system.
       * If the file exists, it is added to the `rejectedBatch` array along with an error message and remove it from the accepted array.
       */
      try {
        // Fetch data for the file based on its unique ID
        const data: UniqueImageResponseObject | null = await fetchByUniqueId(metadataBatch[j]);

        if (data && data.found) {
          const { resources = [] } = data?.response || {};
          if (resources.length) {
            for (let i = 0; i < resources.length; i++) {
              const resource = resources[i];
              if (
                resource.width === renderDataBatch[j]?.width &&
                resource.height === renderDataBatch[j]?.height &&
                resource.bytes === acceptedBatch[j].size
              ) {
                // Add the file to the `rejectedBatch` array along with an error message
                rejectedBatch.push({
                  file: acceptedBatch[j],
                  errors: [
                    {
                      code: 'image-exists',
                      message: `This image is already in the system`,
                    },
                  ],
                  asset_id: resource.asset_id, // This will now be the first item in 'wires' folder if it exists
                  folder: resource.folder,
                });
                // Remove the file from the `accepted` array
                accepted.pop();
                renderData.pop();
                metadata.pop();
                break;
              }
            }
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    rejected.push(...rejectedBatch);

    // the next starting place is
    i += filesUntilLimit;
    totalFilesLength = currentNumberOfAcceptedFiles + accepted.length;
  }

  // move files that exceed the limit to rejected
  if (currentNumberOfAcceptedFiles + acceptedFiles.length > maxImages) {
    const excessFiles = acceptedFiles.slice(i).map((file) => ({
      errors: [
        {
          code: 'max-files',
          message: `Too many files added to the uploader (Limit ${maxImages})`,
        },
      ],
      file,
    }));

    rejected.push(...excessFiles);
  }

  rejected.sort((a, b) => {
    if (a?.folder?.startsWith('wires') && !b.folder?.startsWith('wires')) {
      return -1;
    }
    if (!a?.folder?.startsWith('wires') && b.folder?.startsWith('wires')) {
      return 1;
    }
    return 0;
  });
  // only allow pass 10 errors to user
  return { accepted, rejected: rejected.slice(0, 10), renderData, metadata };
};

export default processDroppedFiles;
