import React, { useRef, useCallback, useState, useEffect } from 'react';
import {
  PageWrapper,
  Loader,
  Button,
  Group,
  Sidebar,
  useSidebarState,
  IconUpload,
  FabButton,
  IconCheckCircle,
  IconImageGallery,
} from '@screentone/core';
import { useAuth, User } from '@screentone/addon-auth-wrapper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';

import Searchbar from '../../components/Searchbar/Searchbar';
import SearchTitle from '../../components/SearchTitle/SearchTitle';
import ImageList from '../../components/ImageList/ImageList';
import Gallery from '../../components/Gallery/Gallery';
import Pagination from '../../components/Pagination/Pagination';
import { useSearch } from '../../hooks/useSearch';
import CustomErrorMessage from '../../components/CustomErrorMessage';
import NoResults from '../../components/NoResults/NoResults';

import { useConfig } from '../../hooks/useConfig';
import useAlert from '../../hooks/useAppAlerts';
import Lightbox from '../../components/Lightbox/Lightbox';
import useLightbox from '../../hooks/useLightbox';
import { iframe, constants, helpers, regex } from '../../utils';
import { getLastPublished, checkIfPublished } from '../../utils/helpers';

import type { ImageType } from '../../types';
import type { SearchViewOptionsType } from '../../types/search';

// the layout component that also calls the differe
// since we can't call the hooks from the Property() component
// TBD whether it gets broken out into its own page
const emptyOptions = {
  until: '',
  since: '',
  query: '',
  user: null,
  statusFilter: null,
};

function SearchLayout() {
  const { user, userAccess } = useAuth();
  const {
    authFetch,
    session: { env, property, appUrl },
  } = useConfig();

  const navigate = useNavigate();

  const advancedSearchInfo = constants.PROPERTIES.getAdvancedSearch(property);
  const showAdvanced = advancedSearchInfo?.show_advanced || false;

  const { alertComponent, setAlert } = useAlert();
  const {
    state,
    options,
    hasAppliedOptions,
    cldUsers,
    triggerRefresh,
    resetOptions,
    openImage,
    setOpenImage,
    setImage,
    setLayout,
  } = useSearch();

  const [view, setView] = useState<SearchViewOptionsType>('grid');

  const { state: routerState = {} } = useLocation();
  const { authState } = useAuth();

  if (authState?.accessToken?.accessToken) setLayout(`search_${property}`);

  const { query } = options;
  const { resources: images = [] } = state.searchResults;
  const { actions: lightboxActions, dispatch: lightboxDispatch, state: lightboxState } = useLightbox();
  const sendImageArray = iframe.sendImageArray();
  const {
    status: lightboxStatus,
    openSidebar: openLightbox,
    collapseSidebar: collapseLightbox,
    sidebarProps: lightboxSidebarProps,
    pageProps: lightboxPageProps,
  } = useSidebarState({
    type: 'slide',
    position: 'right',
    status: sendImageArray ? 'open' : 'closed',
  });
  lightboxSidebarProps.top = '0px';
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [showOneTimeUse] = useState(true);

  // turn on Add to lightbox buttons if needed for image cards
  let lightboxButtons;

  // props for <Gallery> to handle lightbox buttons on gallery items
  let galleryLightboxHandlers;

  if (sendImageArray) {
    lightboxButtons = {
      onAddToLightbox: (img: ImageType, id: string) => {
        const publishedIdsObj = helpers.createPublishedIdsObj(img as ImageType, property, env);
        lightboxDispatch({ type: lightboxActions.ADD_PUBLISHEDIDS_OBJ, payload: { image: img, id, publishedIdsObj } });
        lightboxDispatch({ type: lightboxActions.ADD_IMAGE, payload: { image: img, id } });
      },
      onRemoveFromLightbox: (img: ImageType) => lightboxDispatch({ type: lightboxActions.REMOVE_IMAGE, payload: img }),
    };

    galleryLightboxHandlers = {
      onAddToLightbox: (image: ImageType | null = null, e?: any, id?: string) => {
        if (lightboxStatus !== 'open') {
          openLightbox();
        }

        const publishedIdsObj = helpers.createPublishedIdsObj(image as ImageType, property, env);
        lightboxDispatch({ type: lightboxActions.ADD_PUBLISHEDIDS_OBJ, payload: { image, id, publishedIdsObj } });
        lightboxDispatch({ type: lightboxActions.ADD_IMAGE, payload: { image, images, e, id } });
        lightboxDispatch({ type: lightboxActions.CLEAR_SELECTED_FOR_DRAG });
      },
      onRemoveFromLightbox: (img: ImageType) => {
        if (lightboxStatus !== 'open') {
          openLightbox();
        }
        lightboxDispatch({ type: lightboxActions.REMOVE_IMAGE, payload: img });
      },
    };
  }

  const toggleView = (nextView: SearchViewOptionsType) => {
    localStorage.setItem('lastView', nextView);
    setView(nextView);
  };
  useEffect(() => {
    const storedView = localStorage.getItem('lastView') as SearchViewOptionsType;
    if (storedView) {
      setView(storedView);
    }
  }, []);

  useEffect(() => {
    if (routerState && routerState.from === 'header-app-name' && state.status !== 'loading') {
      resetOptions();
      navigate(`/${property}`, { replace: true });
    }
  }, [routerState, state.status]);

  const addToStoryHandler = useCallback(async () => {
    try {
      const publishedImages: ImageType[] = [];
      const unpublishedImages = lightboxState.images.filter((image, idx) => {
        const isPublished = checkIfPublished(image, property) || !!lightboxState.publishedIdsObj[image.asset_id];
        let publishedId;

        if (!isPublished) {
          const imageFromGallery = images?.find((img) => img.asset_id === image.asset_id);
          // Look in the image list just in case it was published after added to the lightbox
          publishedId = imageFromGallery ? getLastPublished(imageFromGallery, property) : null;
          if (isPublished) {
            // update out the lightbox image
            lightboxState.images[idx] = imageFromGallery as ImageType;
          }
        } else {
          publishedImages.push(image);
          return false;
        }

        return !publishedId;
      });

      if (unpublishedImages.length > 0) {
        const newlyPublished: ImageType[] | [] = await Promise.all(
          unpublishedImages.map((image) =>
            authFetch(`/api/:property/${encodeURIComponent(image.public_id)}/publish`, {
              method: 'POST',
            }),
          ),
        );

        publishedImages.push(...newlyPublished);

        // Updates lightbox state with published images
        publishedImages.forEach((publishedImage) => {
          if (publishedImage) {
            lightboxDispatch({ type: lightboxActions.UPDATE_IMAGE, payload: { image: publishedImage } });
          }
        });

        if (publishedImages.length) {
          setAlert('Images Published', { type: 'success', icon: IconCheckCircle });
        }
      }

      // Syncs recently published with the list from lightbox
      const syncPublished = lightboxState.images
        .map((image) => {
          if (!Object.keys(image.context).length) {
            const newImage = publishedImages.find((publishedImage) => publishedImage.asset_id === image.asset_id);
            newImage && setImage(newImage);
            return newImage;
          }

          return image;
        })
        .filter((image) => image !== undefined);

      await iframe.sendToSource({
        image: syncPublished as ImageType[],
        env,
        property,
        appUrl,
        authFetch,
        ids:
          typeof lightboxState.publishedIdsObj === 'object'
            ? Object.keys(lightboxState.publishedIdsObj)
            : (lightboxState.publishedIdsObj as string[]),
      });
      lightboxDispatch({ type: lightboxActions.REMOVE_IMAGE_ALL });
    } catch (error: any) {
      console.error('publishImage error: ', error);
      setAlert(error.message || 'Error publishing image', { type: 'error' });
    }
  }, [appUrl, property, env, lightboxActions, lightboxDispatch, lightboxState.images, setAlert, setImage]);

  return (
    <DndProvider backend={HTML5Backend}>
      {alertComponent}
      <Lightbox
        onAdd={addToStoryHandler}
        onRemove={(img) => lightboxDispatch({ type: lightboxActions.REMOVE_IMAGE, payload: img })}
        onRemoveAll={() => lightboxDispatch({ type: lightboxActions.REMOVE_IMAGE_ALL })}
        images={lightboxState.images}
        openSidebar={openLightbox}
        collapseSidebar={collapseLightbox}
        status={lightboxStatus}
        sidebarProps={lightboxSidebarProps}
        property={property}
        env={env}
      />
      <Sidebar.Page {...lightboxPageProps}>
        <PageWrapper>
          <Searchbar showOneTimeUse={showOneTimeUse} showAdvanced={showAdvanced} />
          <SearchTitle
            showReset={hasAppliedOptions()}
            onRefresh={triggerRefresh}
            onReset={() => {
              if (searchInputRef.current) {
                searchInputRef.current.value = '';
              }
              if (!isEqual(options, emptyOptions) && !isEqual(cldUsers, { selectedUser: null })) {
                resetOptions();
              }
            }}
            onSwitchView={toggleView}
            query={query}
            view={view}
          />
        </PageWrapper>
        {state.status === 'loading' && (
          <Group margin={{ all: 'xl' }} align="center">
            <Group.Item padding={{ all: 'xl' }}>
              <Loader size="lg" />
            </Group.Item>
          </Group>
        )}

        {state.status === 'ready' && images.length === 0 && (
          <PageWrapper>
            <NoResults />
          </PageWrapper>
        )}

        {state.status === 'error' && (
          <PageWrapper>
            <CustomErrorMessage
              title="Something went wrong"
              message={`We couldn't load the results, please try again. ${state.error?.message || 'unknown error'}. `}
              illustration="blank"
              Action={
                <Button data-testid="search-button" primary onClick={triggerRefresh}>
                  Retry
                </Button>
              }
            />
          </PageWrapper>
        )}

        {state.status === 'ready' && view === 'grid' && images.length > 0 && (
          <>
            <Gallery
              images={images}
              fullWidth={lightboxStatus === 'open' || lightboxStatus === 'opening'}
              setImage={setImage}
              lightboxResources={{
                actions: lightboxActions,
                dispatch: lightboxDispatch,
                state: lightboxState,
              }}
              openImage={openImage}
              setOpenImage={setOpenImage}
              {...galleryLightboxHandlers}
              publishedId={regex.id.im.test(query || '') ? query : undefined}
            />
          </>
        )}
        {state.status === 'ready' && view === 'list' && images.length > 0 && (
          <PageWrapper>
            <ImageList
              images={images}
              user={user as User}
              selected={lightboxState?.images || []}
              setImage={setImage}
              publishedId={regex.id.im.test(query || '') ? query : undefined}
              {...lightboxButtons}
            />
          </PageWrapper>
        )}
        {state.status === 'ready' && <Pagination />}
      </Sidebar.Page>
      {iframe.isUploadEnabled() && userAccess(`${property}.upload`) ? (
        <FabButton
          data-testid="upload-btn"
          icon={IconUpload}
          componentEl={Link}
          to="./upload"
          relative="path"
          toolTipContent="Upload Images"
          right={
            sendImageArray && (lightboxStatus === 'open' || lightboxStatus === 'opening')
              ? 'var(--st-sidebar-width-open)'
              : 'var(--st-sidebar-width-collapsed)'
          }
        >
          <FabButton.FabAdditional>
            <FabButton
              data-testid="upload-promo-images-btn"
              simple
              inverted
              icon={IconImageGallery}
              toolTipContent="Upload Promo Image"
              componentEl={Link}
              relative="path"
              to="./upload/dynamic"
            />
          </FabButton.FabAdditional>
        </FabButton>
      ) : null}
    </DndProvider>
  );
}

export default SearchLayout;
